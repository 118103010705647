import React, {Component} from "react";
import {AppData, customEvent, uniqueId} from "../framework/bundle";

class ModalWindow extends Component {

    constructor(props){
        super(props);
        //let modalSizeClass = props.modalClass || ""; //modal-lg, modal-sm

        this.state = {
            id: uniqueId("modal-window") || props.id,
            bodyId: uniqueId("modal-body"),
            modalSizeClass: props.modalSizeClass || "", //modal-lg, modal-sm, modal-xl

            modalTitle: props.modalTitle || "",
            modalBody: props.modalBody || "",
            modalBodyStyle: {},
            modalButtons: (<button type="button" className="btn btn-default" data-dismiss="modal">Close</button>),
            buttons: [],
        };

        this.timer = null;
    }

    componentDidMount(){
        let e = AppData.obj.app;
        e.addEventListener("app-resize", this.handleSizing);
        this.handleSizing();
    }
    componentWillUnmount(){
        let e = AppData.obj.app;
        e.removeEventListener("app-resize", customEvent, false);
    }


    handleSizing=()=>{
        let e = document.getElementById(this.state.bodyId);
        if (e){
            let h = (window.innerHeight - 260)+"px";
            e.style.height = h;
        }

    };

    show=()=>{
        window.$(`#${this.state.id}`).modal('show');
    };
    hide=()=>{
        window.$(`#${this.state.id}`).modal('hide');
    };

    //modal-sm, modal-lg, modal-xl, "" -empty class = middle modal
    setClass=(className)=>{
        if ( !((className === "modal-sm") || (className === "modal-lg") || (className === "modal-xl")) ) className = "";
        //console.log(className);
        this.setState({modalSizeClass: className});
    };
    //Text
    setTitle=(title)=>{
        this.setState({modalTitle: title});
    };
    //JSX
    setBody=(content, callback)=>{
        this.setState({modalBody: content},()=>{
            if (typeof callback === "function"){
                callback();
            }
        });
    };
    setBodyStyle=(styleObj)=>{
        this.setState({modalBodyStyle:styleObj});
    };
    //JSX
    setButtons=(content, callback)=>{
        this.setState({modalButtons: content},()=>{
            if (typeof callback === "function"){
                callback();
            }
        });
    };


    render(){
        return (
            <div className="modal fade" tabIndex="-1" role="dialog" id={this.state.id}>
                <div className={`modal-dialog ${this.state.modalSizeClass}`} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{this.state.modalTitle}</h4>
                        </div>

                        <div className="modal-body"
                            style={this.state.modalBodyStyle}
                            id={this.state.bodyId}>
                                {this.state.modalBody}
                        </div>

                        <div className="modal-footer">
                            {this.state.modalButtons}
                        </div>

                        {this.state.modalForm}

                        {/*this.state.report*/}
                    </div>
                </div>
            </div>
        );
    }
}
export default ModalWindow;
