import React, { Component, createRef } from 'react';
import Login from './components/Login';
import WorkSpace from './components/WorkSpace';
import {AppData, customEvent, postRequest} from './components/framework/bundle';
import packageJson from '../package.json';

import bg from './lang/bg';
import en from './lang/en';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faCog, faExpandArrowsAlt, faCar, faCircle, faExclamationTriangle, faInfoCircle, faSignInAlt, faCircleNotch, faUsers, faUser, faLock, faSync, faArrowUp, faPlusCircle, faTimes, faTimesCircle, faDrawPolygon, faBars} from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare as farCheckSquare, faSquare as farSquare, faFilePdf as farFilePdf, faFileExcel as farFileExcel} from '@fortawesome/free-regular-svg-icons'

library.add(faSignOutAlt, faCog, faExpandArrowsAlt, faCar, farCheckSquare, farSquare, faCircle, faExclamationTriangle, faInfoCircle, faSignInAlt, faCircleNotch, faUsers, faUser, faLock, farFilePdf, farFileExcel, faSync, faArrowUp, faPlusCircle, faTimes, faTimesCircle, faDrawPolygon, faBars);

class App extends Component {

    constructor(){
        super();

        AppData.config = window.config;
        AppData.langs = {};
        AppData.langs.bg = bg.bg;
        AppData.langs.en = en.en;
        AppData.obj = {};
        AppData.ver = packageJson.version;

        document.title = AppData.config.appTitle;

        window.onresize=()=>{
            customEvent("app-resize");
        };

        this.state = {
            lang: AppData.config.defaultLang, //default language
            isLogged: "loading",
        };
        /*
        this.myRefs = {
            workSpaceRef: createRef(),
        };
        this.workSpace = this.myRefs.workSpaceRef.current;
         */
        // Login Data from login form

    };

    componentDidMount=()=>{
        AppData.obj.app = document.getElementById("appID");
        let e = AppData.obj.app;
        e.addEventListener("logoutEvent", this.handleLogout);
        e.addEventListener("changeLang", this.handleLangChange);

        this.isLogged();

    };
    componentWillUnmount=()=>{
        let e = AppData.obj.app;
        e.removeEventListener("logoutEvent", customEvent, false);
        e.removeEventListener("changeLang", customEvent, false);
    };

    handleLogin =()=> {
        this.setState({isLogged: true});
    };
    //Проверка дали е логнат user при рефреш
    isLogged =()=> {
        postRequest("isLogged", {}, (responseData)=>{
            if (responseData.isLogged) this.handleLogin();
        });
    };
    handleLogout =()=> {
        this.setState({isLogged: false});
    };
    handleLangChange =(e)=> {
        this.setState({lang: e.detail.lang});
    };

    render() {
        if (this.state.isLogged === true) {
            return (
                <div id={"appID"}>
                    <WorkSpace
                        lang={this.state.lang}
                        ver={packageJson.version}
                    />
                </div>
            );
        }
        else if (this.state.isLogged === false)  {
            return (
                <div id={"appID"}>
                    <Login
                        lang={this.state.lang}
                        callbackOnLogin={this.handleLogin}
                        ver={packageJson.version}
                    />
                </div>
            );
        }
        else {
            return (
                <div id={"appID"} />
            )
        }
    }

}export default App;