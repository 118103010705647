import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class LabeledInput extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            value:""
        };

    };

    handleOnChange = (e) => {
        this.setState({value: e.target.value});
        this.props.handleOnChange({
            "key":   this.props.name,
            "value": e.target.value
        });
    };

    //<i className={`icon-append fa ${faiconclass}`}> </i>
    //<i className={`fa ${faIcon} txt-color-teal`}> </i>
    render() {
        let {label, faIcon, type, name, placeholder, text} = this.props;
        placeholder = placeholder || '';

        return (
            <section>
                <label className="label">{label}</label>
                <label className="input">
                    <FontAwesomeIcon icon={faIcon} className={"icon-append"} />
                    <input type={`${type}`}
                           name={`${name}`}
                           placeholder={`${placeholder}`}
                           onChange={this.handleOnChange}
                           value={this.state.value}
                    />
                    <b className="tooltip tooltip-top-right"><FontAwesomeIcon icon={faIcon} className={"txt-color-teal"} /> {text}</b>
                </label>
            </section>
        )
    };

} export default LabeledInput;
