import React from "react";
import {AppData, postRequest} from "../../components/framework/bundle";
import BootstrapTable from "../../components/basic/BootstrapTable";

//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import { AppData, customEvent, findParentDataTag, positiveButtons, postRequest, showMsg, showMsgSmart } from "../framework/bundle";
//import { toLonLat, fromLonLat} from 'ol/proj.js';

class UsersModal {

    constructor(lang, workspace) {
        //super();
        this.lang = lang;
        this.workspace = workspace;
        this.users = [];
    }

    loadUsers=()=>{
        let data = {lang: this.lang}
        postRequest("load-users", data, ()=>{
            
        })
    };

    showModal=()=>{
        const l = this.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let modal = this.workspace.myObjects.modalWindow;

        let modalBody = (
            <div className={"row"}>
                <div className={"col-xs-12"}>
                    <BootstrapTable
                        headers={["h11", "h22", "h33"]}
                        rows={[
                            ["1-1","1-2","1-3"],
                            ["21","22","23"],
                            ["31","32","33"],
                            ["41","42","43"],
                            ["51","52","53"],
                        ]}
                    />
                </div>
            </div>
        );

        let modalButtons = (
            <div>
                {/*<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=>{console.log("Save")}}>{q.lSave}</button>*/}
                <button type="button" className="btn btn-default" data-dismiss="modal">{q.lClose}</button>
            </div>
        );

        modal.setClass("modal-xl");
        modal.setTitle(q.sysMenuTextUsers);
        modal.setBody(modalBody);
        modal.setButtons(modalButtons);
        modal.show();

        //this.myObjects.modalWindow.show();
        //let modal = this.myObjects
    };


} export default UsersModal

