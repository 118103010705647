import React, {Component} from 'react';
import {AppData, customEvent} from "./framework/bundle";
import AccountData from "./left_drawer/AccountData";
import GroupList from "./left_drawer/GroupList";
import DevicesList from "./left_drawer/DevicesList";
import GeozonesList from "./left_drawer/GeozonesList";
//import ModalWindow from "./basic/ModalWindow";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import ModalReports from "../reports/ModalReport";
//import BootstrapDataTimePicker from "./basic/BootstrapDataTimePicker";

class LeftDrawer extends Component{

    constructor(props){
        super(props);
        this.workSpace = this.props.workSpace;
    }

    componentDidMount(){
        let e = AppData.obj.app;
        e.addEventListener("app-resize", this.handleSizing);
        this.handleSizing();

        window.$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            if (e.target.hasAttribute("data-id")){
                switch (e.target.getAttribute("data-id")) {
                    case 'DevicesTab':
                        customEvent("deviceTabSelected");
                        break;
                    case 'GeozonesTab':
                        customEvent("geozoneTabSelected");
                        break;

                    default:
                        break;
                }
            }

        });
    }
    componentWillUnmount(){
        let e = AppData.obj.app;
        e.removeEventListener("app-resize", customEvent, false);
    }

    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
    };

    /* Ако прозореца си промени ръзмера списъка с устройствата си сменя височината */
    handleSizing=()=>{
        //info 126
        let deviceInfoElement = document.getElementById("device-info");
        let deviceInfoHeight = deviceInfoElement.offsetHeight;
        let h = (window.innerHeight - (234 + deviceInfoHeight))+"px";//340 //360
        let e = document.getElementById("device-list");
        if (e) e.style.height = h;

        h = (window.innerHeight - 233)+"px";
        let g = document.getElementById("geozones-list");
        if (g) g.style.height = h;
        //this.setState({devicesHeight: h+"px"});
    };

    //Geozones
    geozoneAdd=()=>{

    };
    //Geozones

    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        return(
            <div className={"left-drawer"}>
                <AccountData
                    ref={this.props.myRefs.accountData}
                    lang={l}
                />

                <div className={"drawer-box mt5"}>

                    <div className="tabbable tabs-below">
                        <div className="tab-content">
                            <div className="tab-pane active" id="devices-tab">
                                <GroupList
                                    ref={this.props.myRefs.groupList}
                                    lang={l}
                                />
                                <DevicesList
                                    ref={this.props.myRefs.devicesList}
                                    workSpace={this.workSpace}
                                    lang={l}
                                />
                            </div>
                            <div className="tab-pane" id="gaeozones-tab">
                                <GeozonesList
                                    ref={this.props.myRefs.geozonesList}
                                    lang={l}
                                />
                            </div>
                        </div>
                        <ul className="nav nav-tabs">
                            <li className="active">
                                <a data-toggle="tab" href="#devices-tab" aria-expanded="true" data-id="DevicesTab">{q.lDevices}</a>
                            </li>
                            <li className="">
                                <a data-toggle="tab" href="#gaeozones-tab" aria-expanded="false" data-id="GeozonesTab">{q.lGeozones}</a>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>
        );
    }

} export default LeftDrawer
