const en = {
    //all
    laccount: "Account",
    luser: "User", //userLabel
    lpassword: "Password",
    lenter: "Login", //loginFormButton
    tDevice: "Device",
    lDevices: "Devices",
    lGeozones: "Geozones",
    lauto: 'Automobile', //auto //LeftDrawer - deviceInfo
    ldriver: 'Driver', //driver //LeftDrawer - deviceInfo
    lsee: 'see',
    lSave: 'Save',
    lCancel: 'Cancel',
    lClose: 'Close',
    lReport: 'Report',
    lLoad: "Load",
    lPeriod: 'Time period',
    lChoicePeriod: 'Choice time period',
    lToday: 'Today',
    lLast24: 'Last 24 hours',
    lYesterday: "Yesterday",
    lThisWeek: "This week",
    lLastWeek: "Last week",
    lThisMonth: "This month",
    lLastMonth: "Last month",
    lAdditional: "Additiona data",

    lFromTo: 'From-to',
    lTimeFromTo: 'Time from-to',
    lDrivingTime: 'Time in movement',
    lDistanceKm: 'Distance KM',
    lAvgSpeed: 'Average speed',
    lStopsCount: 'Stop count',
    lFuelConsum: 'Average fuel cons. L',
    lUrbanConsum: 'Urban L',
    lExtraUrbanConsum: 'Extra-Urban L',
    lKm: "km.",
    lL: "l.",
    lKmh: "km/h",
    lLoading: "Loading",
    lAddress: "Address",
    lLastAddress: "Last address",

    lInformation: "Information",

    primaryDriver: 'Primary driver', //LeftDrawer - deviceInfo
    lLastEvent: 'Last event', //LeftDrawer - deviceInfo
    lLastConnection: 'Last connection', //LeftDrawer - deviceInfo
    lOdometer: 'Odometer', //LeftDrawer - deviceInfo
    lMotoHours: 'Moto hoursses', //LeftDrawer - deviceInfo
    lStopedTime: "Stay",
    lImei: "IMEI",

    lmw: 'Attention!', //labelMessageWarning
    lme: 'Error!', //labelMessageError
    lms: 'Message', //labelMessageSuccess
    lmi: 'Information', //labelMessageInfo

    //Login
    headerTitle: "Login to system",
    cai: "Enter your account", //accountInputCaption
    cui: "Enter your username", //userInputCaption
    cpi: "Enter your password", //passwordInputCaption
    cfp: "Forgoten password", //loginForgotenPassword

    //WorkSpace
    btnLogout: "Logout", //buttonLogout
    btnSettings:  "Settings",
    btnFullScreen:  "full screen",

    //LeftDrawer
    titleAcount: "Account",
    titleFilter: "Filter",
    titleDevices: "Devices", //deviceLabel
    lAllDevice: "All devices", //allDeviceLabel

    //deviceMenuList: ["Road list", null, "Mobileye Details", "Mobileye improvements"],
    deviceMenuRoadList: "Road List",
    deviceMenuTrackList: "Track list",
    deviceMenu1: "Tracks",
    deviceMenu2: "Mobileye Details",
    deviceMenu21: "Mobileye Details by driver",
    deviceMenu3: "Geozones",

    //Dawn Drawer
    tDawnDrawer: "Device details",//dawnDrawerTitle
    lStartDate: "Start date",
    lEndDate: "End date",
    lCourses: "Tracks",
    lDayCourses: "Daily tracks",
    lMovements: "Movement",
    lTrackDetails: "Track details",
    lTrackDataDetails: "Track details",
    lMobileyeTrackDetails: "Mobileye details",
    lTrack: "",
    lMovementDistance: "Movement distance:",
    lMovementTime: "Time (movement)",
    lStopTime: "Time (stop)",
    lParkTime: "Time (parked)",

    //Smart messages buttons
    smb:"[No][Yes]",
    //messages
    mlf: "All fields are required!", //messageLoginFields
    mqt: "Logout",
    mqq: "Are you sure?",

    mlw  : "Wrong account, username or password!", //messageLoginWrong
    mlud : "This user are disabled!", //messageLoginUserDisabled
    mlad : "This account is disabled!", //messageLoginAccountDisabled

    mcd : "Choise device",
    mnt: "There is no track on the selected criteria or connection to the server!",
    mntr: "There are no available data on the selected criteria!",
    mOneMonthLimit: "The selected period cannot exceed 31 days!",
    mMobileyeReportSelectDriver: "Please select driver!",

    mBeta: "This is beta version! Some of features may be disabled, be changed or may not working during the beta tests!",

    tsdates: "Select device, start and end dates!",
    tscourse: "Select period and load motion data!",
    tsChosePeriod: "Select a time period!",

    //Geozones
    lGOperations:"Geozones operations",
    lGList:"Geozones list",
    mGeozoneNoEvents: "No events in this period",
    //Geozones end

    //Sysmenu
    sysMenuTextAccounts:"Account",
    sysMenuTextUsers:"User",
    //Sysmenu end

    //Reports
    reportTitleRoadList: "Road Trip",
    reportTitleTrackList: "Tracks list",
    reportTitleMobileyeDetails: "Mobileye details",
    reportTitleMobileyeDetailsByDriver: "Mobileye details by driver",
    reportTitleGeozones: "Geozones",

    msgReportNoEvents: "No events in selected period",
};

export default {en}
