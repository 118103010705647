import React, { Component, createRef } from 'react';
import "ol/ol.css";

import {AppData, customEvent, sleep} from "./framework/bundle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Icon, Style, Fill, Stroke, Circle as CircleStyle} from 'ol/style.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import Polygon from 'ol/geom/Polygon.js';
import LineString from 'ol/geom/LineString';
import {fromLonLat, toLonLat} from 'ol/proj.js';
import Text from 'ol/style/Text';
//import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import {createEmpty, extend, isEmpty} from 'ol/extent';

import Overlay from 'ol/Overlay.js';
import {toStringXY, toStringHDMS} from 'ol/coordinate';
import Draw from "ol/interaction/Draw";

class GpsMap extends Component{

    constructor(props){
        super(props);

        this.state = {
            mapLoading: false
        };
        this.workSpace = props.workSpace;

        this.popupRef = createRef();
    };

    componentDidMount(){
        this.showMap();
        let e = AppData.obj.app;
        e.addEventListener("mapShowDevices", this.mapShowDevices);
        e.addEventListener("mapShowSelectedDevice", (e)=>{this.mapShowSelectedDevice(e.detail.selectedDevicePos)});
        e.addEventListener("mapSetCenterEvent", (e)=>{this.mapSetCenter(e.detail.coordArray, e.detail.duration, e.detail.zoom)});
        e.addEventListener("mapSetZoom", (e)=>{this.mapSetZoom(e.detail.zoom)});
        //e.addEventListener("mapDrawTrack", this.mapClearData);
        //e.addEventListener("mapClearTrack", this.mapClearTrack);
        e.addEventListener("mapClearData", this.mapClearData);
        e.addEventListener("deviceTabSelected", this.deviceTabSelected);
        e.addEventListener("geozoneTabSelected", this.geozoneTabSelected);
    };
    componentWillUnmount(){
        let e = AppData.obj.app;
        e.removeEventListener("mapShowDevices", customEvent, false);
        e.removeEventListener("mapShowSelectedDevice", customEvent, false);
        e.removeEventListener("mapSetCenterEvent", customEvent, false);
        e.removeEventListener("mapSetZoom", customEvent, false);
        //e.removeEventListener("mapDrawTrack", customEvent, false);
        //e.removeEventListener("mapClearTrack", customEvent, false);
        e.removeEventListener("mapClearData", customEvent, false);
    };

    //Set map center & zoom
    mapSetCenter=(coordArray, duration, zoom)=>{
        duration = duration || 0;
        zoom = zoom || this.state.view.getZoom();
        if (this.state.view.getZoom() > 11) duration = 0;
        this.state.view.animate({
            center: (fromLonLat(coordArray)),
            duration: duration
        },()=>{
            this.state.view.setZoom(zoom);
            //customEvent("mapShowDevices", {selectedDevice:0});
        });
        //if ((zoom !== undefined) && (isNaN(zoom))) this.state.view.setZoom(zoom);
    };
    mapSetZoom=(zoom)=>{
        this.state.view.setZoom(zoom);
    };
    //Zoom & fit
    mapSetBounds=(layer, paddingArray)=>{
        paddingArray = paddingArray || [50, 30, 50, 30];
        let extent = createEmpty();
        extend(extent, layer.getSource().getExtent());
        if (!isEmpty(extent)) this.state.view.fit(extent, {padding: paddingArray, constrainResolution: true});
    };

    //Icon styles
    createStyleIcon=(src, img, text)=>{
        const ac = AppData.config;

        return new Style({
            image: new Icon(/** @type {module:ol/style/Icon~Options} */ ({
                anchor: [0.5, 1],
                crossOrigin: 'anonymous',
                src: src,
                img: img,
                //imgSize: img ? [img.width, img.height] : undefined
                imgSize: img ? [32, 32] : undefined
            })),
            text: new Text({
                font: ac.signText.font,
                fill: new Fill(ac.signText.color),
                backgroundFill: new Fill(ac.signText.bgfill),
                backgroundStroke: new Stroke(ac.signText.stroke),
                padding: [2,5,0,5],
                offsetY: -45,
                text: text,
            })
        });
    };
    //Icon styles for strat and stop
    createStyleForPinIcon=(img, zindex=0)=>{
        return new Style({
            image: new Icon(/** @type {module:ol/style/Icon~Options} */ ({
                anchor: [0.5, 1],
                crossOrigin: 'anonymous',
                img: img,
                imgSize: img ? [24, 24] : undefined
            })),
            zIndex: 5000+zindex
        });
    };
    //Lines and tack style
    createStyleForTrackLine=(color, width)=>{
        color = color || '#1500ff';
        width = width || 5;

        return new Style({
                stroke: new Stroke({
                    color: color,
                    //color: '#000066',
                    width: width
                })
            });
    };

    svgIconToImage=(color, icon)=> {
        //const car = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" style="color: ${color};" ><path fill="currentColor" d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z" /></svg>`;
        let svg = "";
        switch(icon){
            case "car":
                svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 117 141">
<defs><style type="text/css"><![CDATA[.fil1 {fill:${color}} .fil0 {fill:white}]]></style></defs>
<g class="currentLayer">
    <circle class="fil0" cx="58" cy="58" r="51" id="svg_1"/>
	<path class="fil1" d="M58,0 c-32,0 -58,26 -58,58 c0,32 26,54 58,83 c33,-29 59,-51 59,-83 c0,-32 -26,-58 -59,-58 zm0,105 c-25,0 -46,-20 -46,-46 c0,-26 21,-47 46,-47 c26,0 47,21 47,47 c0,26 -21,46 -47,46 z" id="svg_2"/>
	<path class="fil1" d="M93,42 l-8,0 l-3,-6 c-2,-6 -8,-10 -14,-10 l-19,0 c-6,0 -12,4 -14,10 l-3,6 l-8,0 c-1,0 -2,1 -2,2 l1,4 c0,1 1,1 2,1 l2,0 c-2,2 -3,4 -3,7 l0,7 c0,2 1,4 3,6 l0,8 c0,2 2,4 4,4 l5,0 c2,0 4,-2 4,-4 l0,-5 l37,0 l0,5 c0,2 2,4 4,4 l5,0 c2,0 4,-2 4,-4 l0,-8 c2,-2 3,-4 3,-6 l0,-7 c0,-3 -2,-5 -3,-7 l2,0 c1,0 2,0 2,-1 l1,-4 c0,-1 -1,-2 -2,-2 l0,0 zm-50,-2 c1,-3 4,-5 6,-5 l19,0 c2,0 5,2 6,5 l3,7 l-37,0 l3,-7 zm-7,23 c-3,0 -5,-2 -5,-5 c0,-2 2,-4 5,-4 c2,0 7,4 7,7 c0,2 -5,2 -7,2 zm45,0 c-2,0 -7,0 -7,-2 c0,-3 5,-7 7,-7 c3,0 5,2 5,4 c0,3 -2,5 -5,5 l0,0 z" id="svg_3"/>
</g>
</svg>
`;
                break;
            case "pin":
                svg = `
<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" color="${color}">
    <path fill="currentColor" d="M144 0C64.47 0 0 64.47 0 144c0 74.05 56.1 134.33 128 142.39v206.43l11.01 16.51c2.38 3.56 7.61 3.56 9.98 0L160 492.82V286.39c71.9-8.05 128-68.34 128-142.39C288 64.47 223.53 0 144 0zm0 256c-61.76 0-112-50.24-112-112S82.24 32 144 32s112 50.24 112 112-50.24 112-112 112zm0-192c-44.12 0-80 35.89-80 80 0 8.84 7.16 16 16 16s16-7.16 16-16c0-26.47 21.53-48 48-48 8.84 0 16-7.16 16-16s-7.16-16-16-16z" />
</svg>                
`;
                break;
            case "pin2":
                svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 117 141">
<defs><style type="text/css"><![CDATA[.fil1 {fill:${color}} .fil0 {fill:white}]]></style></defs>
<g class="currentLayer">
    <circle class="fil0" cx="58" cy="58" r="51" id="svg_1"/>
	<path class="fil1" d="M58,0 c-32,0 -58,26 -58,58 c0,32 26,54 58,83 c33,-29 59,-51 59,-83 c0,-32 -26,-58 -59,-58 zm0,105 c-25,0 -46,-20 -46,-46 c0,-26 21,-47 46,-47 c26,0 47,21 47,47 c0,26 -21,46 -47,46 z" id="svg_2"/>
</g>
</svg>
`;
                break;
            default:
                svg = `
<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512" color="${color}">
    <path fill="currentColor" d="M144 0C64.47 0 0 64.47 0 144c0 74.05 56.1 134.33 128 142.39v206.43l11.01 16.51c2.38 3.56 7.61 3.56 9.98 0L160 492.82V286.39c71.9-8.05 128-68.34 128-142.39C288 64.47 223.53 0 144 0zm0 256c-61.76 0-112-50.24-112-112S82.24 32 144 32s112 50.24 112 112-50.24 112-112 112zm0-192c-44.12 0-80 35.89-80 80 0 8.84 7.16 16 16 16s16-7.16 16-16c0-26.47 21.53-48 48-48 8.84 0 16-7.16 16-16s-7.16-16-16-16z" />
</svg>                
`;
                break;
        }

        let img = new Image();
        img.src = 'data:image/svg+xml,' + escape(svg);
        return img;
    };
    createCarIconForMap=(coords, color, text)=>{
        //Създава иконата която ще се показва на картата
        //coords = [Lng, Lat];
        //color = reen || color:rgba()
        let iconFeature = new Feature(new Point(fromLonLat(coords)));
        iconFeature.set('style', this.createStyleIcon(undefined, this.svgIconToImage(color, "car"), text, false));

        return iconFeature;
    };

    //Draw devices list
    mapShowDevices=(devices, devicesShown, callback, clearTrack=true)=>{
        let iconFeatures = [];
        devices.forEach((e,i)=>{
            let lng = parseFloat(e.longitude);
            let lat = parseFloat(e.latitude);

            if ( (!isNaN(lng)) && (!isNaN(lat)) && (devicesShown[i]) ) {
                let iconFeature = this.createCarIconForMap([lng, lat], e.color, e.device_name);
                iconFeatures.push(iconFeature);
            }
        });

        let devicesLayer = new VectorLayer({
            style: function(feature) {
                return feature.get('style');
            },
            source: new VectorSource({features: iconFeatures})
        });

        if (clearTrack) this.mapClearData();//if (this.state.dataLayer) this.state.map.removeLayer(this.state.dataLayer);
        if (this.state.devicesLayer) this.state.map.removeLayer(this.state.devicesLayer);
        if (this.state.selectedDeviceLayer) this.state.map.removeLayer(this.state.selectedDeviceLayer);
        this.setState({devicesLayer:devicesLayer},()=>{
            if (typeof callback === "function") callback();
        });
        this.state.map.addLayer(devicesLayer);

        //Callback for function not for setState data!!!
        //if (typeof callback === "function") callback();
    };
    mapShowReloadedDevices=(devices, callback)=>{
        //const d = AppData.devices;

        let iconFeatures = [];
        devices.forEach((e)=>{
            let lng = parseFloat(e.longitude);
            let lat = parseFloat(e.latitude);

            if ( (!isNaN(lng)) && (!isNaN(lat)) ) {
                let iconFeature = this.createCarIconForMap([lng, lat], e.color, e.displayName);
                iconFeatures.push(iconFeature);
            }
        });

        let devicesLayer = new VectorLayer({
            style: function(feature) {
                return feature.get('style');
            },
            source: new VectorSource({features: iconFeatures})
        });

        //if (this.state.dataLayer) this.state.map.removeLayer(this.state.dataLayer);
        if (this.state.devicesLayer) this.state.map.removeLayer(this.state.devicesLayer);
        if (this.state.selectedDeviceLayer) this.state.map.removeLayer(this.state.selectedDeviceLayer);
        this.setState({devicesLayer:devicesLayer});
        this.state.map.addLayer(devicesLayer);

        if (typeof callback === "function") callback();
        //
    };
    //Draw selected device in new layer at top
    mapShowSelectedDevice=(device)=>{
        //pos = parseInt(pos);
        //const e = AppData.devices[pos];

        let iconFeatures = [];

        let lng = parseFloat(device.longitude);
        let lat = parseFloat(device.latitude);

        let iconFeature = this.createCarIconForMap([lng, lat], device.color, device.displayName);
        iconFeatures.push(iconFeature);//за да бъде масив

        //let iconFeature = new Feature(new Point(fromLonLat([lng, lat])));
        //iconFeature.set('style', this.createStyleIcon(undefined, this.svgIconToImage(e.color, "car"), e.displayName, true));

        let selectedDeviceLayer = new VectorLayer({
            style: (feature)=>{
                return feature.get('style');
            },
            source: new VectorSource({features: iconFeatures})
        });

        if (this.state.selectedDeviceLayer) this.state.map.removeLayer(this.state.selectedDeviceLayer);
        this.setState({selectedDeviceLayer:selectedDeviceLayer});
        this.state.map.addLayer(selectedDeviceLayer);
    };

    //Draw track
    mapDrawData=(trackData)=> {
        let startPin = this.svgIconToImage("green", "pin2");
        let endPin = this.svgIconToImage("red", "pin2");
        let data = trackData.data;
        let tracks = trackData.track_data_status_change;

        //Изчертаване на линиите от трака
        let i, l, featureLines = [], lastLoc, skipStep = 1;
        let startIcon, endIcon;
        //l = data.length-1;
        //skipStep = Math.ceil(data.length/5000);//TODO тази тъпотия без да исках я изтрих
        //console.log(data.length+"-"+skipStep);
        l = data.length-skipStep;
        for (i=0; i<l; i++) {
            //sleep(100);
            let featureLine, l1, l2;

            l1 = fromLonLat([data[i].lng, data[i].lat]);
            l2 = lastLoc = fromLonLat([data[i+1].lng, data[i+1].lat]);

            featureLine = new Feature({geometry: new LineString([l1, l2]), name: "lineID-" + (i)});
            //featureLine = new Feature({geometry: new LineString([l1, l2])});
            featureLines.push(featureLine);
        }
        //Стопове
        let sl = tracks.length, s, stopFeature;
        for (s=0;s<sl;s++){
            if ((tracks[s].status === 1) || (tracks[s].status === 2)) {
                stopFeature = this.featureStop(fromLonLat([data[tracks[s].start].lng, data[tracks[s].start].lat]), s, tracks[s].status);
                featureLines.push(stopFeature);
            }
        }

        //Icons for start & end
        endIcon = new Feature(new Point(lastLoc));
        endIcon.setStyle(this.createStyleForPinIcon(endPin,1));
        featureLines.push(endIcon);
        startIcon = new Feature(new Point(fromLonLat([data[0].lng, data[0].lat])));
        startIcon.setStyle(this.createStyleForPinIcon(startPin,2));
        featureLines.push(startIcon);

        let source = new VectorSource({
            features: featureLines
        });

        featureLines = null;
        data = null;

        let dataLayer = new VectorLayer({
            source: source,
            style: [this.createStyleForTrackLine("#1500ff", 5)]
        });

        this.mapSetBounds(dataLayer);

        this.mapClearData();
        this.state.map.addLayer(dataLayer);
        this.setState({dataLayer: dataLayer});

    };
    mapDrawTrack=(data, track)=> {
        //http://jsfiddle.net/goldrydigital/x4gdxweo/
        let startPin = this.svgIconToImage("green", "pin2");
        let endPin = this.svgIconToImage("red", "pin2");
        //let data = trackData;
        //console.log(data);
        //let tracks = trackData.tracks;

        //Изчертаване на линиите от трака
        let i, l, featureLines = [], lastLoc;
        let startIcon, endIcon;
        //l = data.length;
        for (i=track.getStartID(); i<track.getEndID(); i++) {
            //sleep(100);
            let featureLine, l1, l2;

            l1 = fromLonLat([data[i].lng, data[i].lat]);
            l2 = lastLoc = fromLonLat([data[i+1].lng, data[i+1].lat]);

            featureLine = new Feature({geometry: new LineString([l1, l2]), name: "TrackID-" + (i)});
            featureLines.push(featureLine);
        }

        //Икони за начало и край на трака
        endIcon = new Feature(new Point(lastLoc));
        endIcon.setStyle(this.createStyleForPinIcon(endPin,1));
        featureLines.push(endIcon);
        startIcon = new Feature(new Point(fromLonLat([data[track.getStartID()].lng, data[track.getStartID()].lat])));
        startIcon.setStyle(this.createStyleForPinIcon(startPin,2));
        featureLines.push(startIcon);

        let source = new VectorSource({
            features: featureLines
        });

        featureLines = null;
        data = null;

        //Ако не се изчертават само траковете да се покажат с друг цвят и по-тънки за да се виждат върху другите
        let track_style;
        if (this.workSpace.options.track_only) track_style = [this.createStyleForTrackLine("#1500ff", 5)];
        else track_style = [this.createStyleForTrackLine("#1ef7ff", 3)];
        let trackLayer = new VectorLayer({
            source: source,
            style: track_style
            //style: [this.createStyleForTrackLine("#1ef7ff", 3)]
        });

        //this.mapSetBounds(trackLayer);
        this.mapClearTrack();
        this.state.map.addLayer(trackLayer);
        this.setState({trackLayer: trackLayer});

    };
    mapClearData=()=>{
        //Изтриване на стария трак ако съществува и рисуване на новия
        if (this.state.dataLayer) this.state.map.removeLayer(this.state.dataLayer);
        if (this.state.trackLayer) this.state.map.removeLayer(this.state.trackLayer);
    };
    mapClearTrack=()=>{
        //Изтриване на стария трак ако съществува и рисуване на новия
        if (this.state.trackLayer) this.state.map.removeLayer(this.state.trackLayer);
    };
    showMap=()=>{
        const overlay = new Overlay({
            element: document.getElementById("element"),
        });

        let view = new View({
            //center: fromLonLat([25.9289313, 42.2746605]),
            center: fromLonLat([26.9289313, 43.2746605]),
            zoom: 7,
            maxZoom: 19,
        });
        const map = new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    source: new OSM()

                    /*
                    https://openlayers.org/en/latest/examples/bing-maps.html
                    Bing Maps
                    import BingMaps from 'ol/source/BingMaps.js';

                    source: new BingMaps({
                        key: 'Your Bing Maps Key from http://www.bingmapsportal.com/ here',
                        imagerySet: styles[i]
                        // use maxZoom 19 to see stretched tiles instead of the BingMaps
                        // "no photos at this zoom level" tiles
                        // maxZoom: 19
                    })
                    */

                })
            ],
            loadTilesWhileAnimating: true,
            loadTilesWhileInteracting: true,
            view: view,
            overlays: [overlay],
            //projection: "EPSG:4326" //"EPSG:900913"
        });


        this.setState({map: map});
        this.setState({view: view});
        this.setState({currentZoom: view.getZoom()});

        /*
        map.on('click',(e)=>{
            console.log(e.coordinate)
        });
         */

        /*
        map.on('click',()=>{
            //this.mapCenter(map, view, [26.9289313, 43.2746605]);
            //view.setZoom(7);
            //this.mapSetZoom(7)
            //0-19
        });



        ZOOM EVENT
        map.on('moveend', (e)=>{
            let newZoom = view.getZoom();
            if (newZoom !== this.state.currentZoom){
                this.setState({currentZoom: newZoom});
            }
        });
        */
        map.on('pointermove', (e)=>{
            if (this.state.lastLineFeature) this.state.lastLineFeature.setStyle([this.createStyleForTrackLine()]);
            map.forEachFeatureAtPixel(e.pixel, (feature)=>{
                if (feature.values_.name){
                    let f = feature.values_.name.split("-");
                    let fname = f[0];
                    if (fname === 'lineID') {

                        //this.setState({lastLineFeature})
                        //for
                        if (this.state.lastLineFeature) this.state.lastLineFeature.setStyle([this.createStyleForTrackLine()]);
                        this.setState({lastLineFeature: feature});
                        feature.setStyle(this.createStyleForTrackLine("#00a8ff", 5));
                    }
                }

            });
        });

    };

    setLoading=(mapLoading)=>{
        this.setState({mapLoading:mapLoading});
    };

    //Draw on map
    deviceTabSelected=()=>{
        //this.drawEnd();
    };
    geozoneTabSelected=()=>{
        //this.drawStart();
    };
    drawStart=(callbackOnEnd)=>{
        //var value = typeSelect.value;
        //TODO StopTimer for devices
        let drawSource = new VectorSource({wrapX: false});
        let drawLayer = new VectorLayer({
            source: drawSource
        });

        /*
        this.state.draw = new Draw({
            source: drawSource,
            type: 'Polygon'
        });
         */

        let draw = new Draw({
            source: drawSource,
            type: 'Polygon'
        });

        this.setState({draw: draw});

        this.drawRemoveLayer();
        this.state.map.addInteraction(draw);
        this.state.map.addLayer(drawLayer);
        //Когато започне рисуването
        draw.on('drawstart', ()=>{

        });
        //Когато свърши рисуването
        draw.on('drawend', (e)=>{
            this.drawStop();
            //this.state.map.removeInteraction(this.state.draw);
            //let coordinateArray = e.feature.getGeometry().getCoordinates()[0];
            let coordinateArray = e.feature.getGeometry().getCoordinates()[0];
            if (typeof callbackOnEnd === "function") this.setState({drawLayer: drawLayer}, callbackOnEnd(coordinateArray));
            else this.setState({drawLayer: drawLayer});
        });


    };
    drawStop=()=>{
        this.state.map.removeInteraction(this.state.draw);
    };
    drawRemoveLayer=()=>{
        if (this.state.drawLayer) this.state.map.removeLayer(this.state.drawLayer);
    };
    //Draw on map end

    //Geozones
    geozoneRemoveLayer=()=>{
        if (this.state.geozoneLayer) this.state.map.removeLayer(this.state.geozoneLayer);
    };
    geozoneShowSelected=(geozone)=>{
        this.geozoneRemoveLayer();

        let feature = new Feature({
            geometry: new Polygon([geozone.geometry])
        });
        let geozoneLayer = new VectorLayer({
            source: new VectorSource({
                features: [feature]
            })
        });

        this.mapSetBounds(geozoneLayer);
        this.setState({geozoneLayer:geozoneLayer},()=>{
            this.state.map.addLayer(geozoneLayer);
        });

    };
    //Geozones end

    featureStop=(coords, i, styleType)=>{
        let textStyle;
        switch (styleType) {
            default:
                //case stop
                textStyle = new Style({
                    image: new CircleStyle({
                        radius: 12,
                        fill: new Fill({color: 'rgba(255, 0, 0, 0.6)'}),
                        //fill: new Fill({color: '#cc0000'}),
                    }),
                    text: new Text({
                        font: 'bold 10px sans-serif',
                        fill: new Fill({color: "#ffffff"}),
                        textBaseline: "middle",
                        textAlign: 'center',
                        text: String(i+1),
                        //backgroundFill: new Fill({color: '#cc0000'}),
                    }),
                    zIndex: (1000+i)
                });
                break;
            case 1:
                textStyle = new Style({
                    image: new CircleStyle({
                        radius: 12,
                        fill: new Fill({color: 'rgba(182, 0, 204, 0.6)'}),
                        //fill: new Fill({color: '#b600cc'}),
                    }),
                    text: new Text({
                        font: 'bold 10px sans-serif',
                        fill: new Fill({color: "#ffffff"}),
                        textBaseline: "middle",
                        textAlign: 'center',
                        text: String(i+1),
                        //backgroundFill: new Fill({color: '#b600cc'}),
                    }),
                    zIndex: (1000+i)
                });
                break;
        }

        let stopFeature = new Feature(new Point(coords));
        stopFeature.setStyle(textStyle);

        return stopFeature;
    };

    popoverShowDataDetails=(dataRow)=>{
        //console.log(dataRow);
        let popup = new Overlay({
            element: document.getElementById('popup'),
            offset: [0,0]
        });

        this.state.map.addOverlay(popup);
        let element = popup.getElement();
        window.$(element).popover('destroy');

        let coordinates = [parseFloat(dataRow.lng), parseFloat(dataRow.lat)];
        popup.setPosition(fromLonLat(coordinates));

        const __this = this;
        //'<a href="#" target="_blanc">'+dataRow.lng+'/'+dataRow.lat+'</a>' +
        let engine_status;
        //let direction = <FontAwesomeIcon icon={"arrow-up"} style={{transform:"rotate("+dataRow.angle+"deg)"}}/>
        //let direction = '<span style="color: red; font-weight: bold; transform: rotate(35deg)">&uarr;</span>';
        let direction = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" class="svg-inline--fa fa-arrow-up fa-w-14 fa-null fa-rotate-null fa-pull-null " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" ' +
            'style="transform: rotate('+dataRow.angle+'deg);">' +
            '<path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" /></svg>';
        switch (dataRow.engine_status){
            default:
                engine_status  = "Включено";
                break;
            case "0":
                engine_status  = "Изключено";
                break;
        }


        let popup_can_text = '';
        //Ако има мануал кан
        if(this.workSpace.myObjects.devicesList.state.selectedDeviceInfo.device_options.manual_can === "1") {

            if (dataRow.can_speed !== undefined){
                if (dataRow.can_speed !== null) popup_can_text += '<b>Кан скорост:</b> ' + dataRow.can_speed + ' km/h<br />';
                else popup_can_text += '<b>Кан скорост:</b> N/A<br />';
            }
            if (dataRow.can_total_fuel !== undefined) popup_can_text += '<b>Кан гориво (общо):</b> ' + dataRow.can_total_fuel + ' l<br />';
            if (dataRow.can_tank_fuel !== undefined) popup_can_text += '<b>Кан резервоар:</b> ' + dataRow.can_tank_fuel + ' %<br />';
            if (dataRow.can_rpm !== undefined) popup_can_text += '<b>Кан обороти:</b> ' + dataRow.can_rpm + ' rpm<br />';
            if (dataRow.can_engine_time !== undefined) popup_can_text += '<b>Кан двигател (часове):</b> ' + dataRow.can_engine_time + ' h<br />';
            if (dataRow.can_distance !== undefined) popup_can_text += '<b>Кан разтояние:</b> ' + dataRow.can_distance + ' km<br />';
            if (dataRow.can_engine_temperature !== undefined) popup_can_text += '<b>Кан двигател t°:</b> ' + dataRow.can_engine_temperature + ' t°<br />';

        }

        this.popupText =
            '<b>GPS скорост:</b> '+ dataRow.speed+' km/h<br />'+
            '<b>Запалване:</b> '+engine_status+'<br />'+
            '<b>Координати:</b> '+dataRow.lng+' / '+dataRow.lat+'<br />'+
            popup_can_text+
            direction+'<br />'+
            '';
        window.$(element).popover({
            title : '<span class="text-info"><strong>Информация</strong></span>'+
                '<button type="button" id="close" class="close" onclick="window.$(&quot;#popup&quot;).popover(&quot;hide&quot;);">&times;</button>',
            placement: 'top',
            animation: false,
            html: true,
            content: __this.popupText,
            trigger: 'focus'
        });

        this.mapSetCenter(coordinates);
        window.$(element).popover('show');


    };

    render(){
        const ac = AppData.config;
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let loading;

        /*
        if (this.state.mapLoading){
            loading = (
                <div className={"map-loading"}>
                    <div><FontAwesomeIcon icon="circle-notch" spin size="3x" /><br /><strong>{q.lLoading}</strong></div>
                </div>
            );
        }
         */



        return(
            <div className={"gps-map-container"}>
                <div className={"logo1"}><img src={ac.logo1.src} alt={ac.logo1.text} /></div>
                <div className={"logo2"}><img src={ac.logoMobileye.src} alt={ac.logoMobileye.text} /></div>
                <div id={"map"} className={"gps-map"} />
                <div id="popup" ref={this.popupRef} />
            </div>
        )
    }
}

export default GpsMap;
//TODO Ако има селектното устройство да се рендва най от горе а не да рендва още един леяр
//TODO да се настрои вюто на нарисуваните икони
//http://jsfiddle.net/goldrydigital/x4gdxweo/
