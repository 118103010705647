import Track from "../items/Track";

class TrackHandle{

    /**
     * Convert JSON received track data array to track object array
     * @param tracks (JSON array of track data)
     * @returns {Track[]}
     */
    tracksArrayToTracksArrayObjects(tracks){
        let track_list = [];
        track_list = tracks.map((track, i) => {
            let track_obj = new Track();
            track_obj.setID(i);
            track_obj.setStatus(track.status);
            track_obj.setStartID(track.start_id);
            track_obj.setEndID(track.end_id);
            track_obj.setTotalTime(track.total_time);
            track_obj.setStopTime(track.stop_time);
            track_obj.setStopCount(track.stop_count);
            track_obj.setDistance(track.distance);
            return track_obj;
        });
        return track_list;
    }
} export default TrackHandle
