import React, {Component} from 'react';
import {AppData} from "../framework/bundle";

/* Първи бокс с описание на акунта */
class AccountData extends Component{

    constructor(props){
        super(props);
        this.state = {
            lang: props.lang,
            account: {},
            user: {},
        };
    }

    setAccountUser=(account, user)=>{
        this.setState({account:account, user:user});
    };

    render(){
        const a = AppData;
        const l = this.props.lang;
        const q = a.langs[l]; //Масива с езиците

        return (
            <div className={"drawer-box"}>
                <div className={"title"}><h2>{q.titleAcount}</h2></div>
                <div>
                    <div className={"col-xs-5 bold"}>{q.laccount}:</div>
                    <div className={"col-xs-7 text-over c-blue"}>{this.state.account.contact_name}</div>
                    <div className={"clear"} />
                </div>
                <div>
                    <div className={"col-xs-5 bold"}>{q.luser}:</div>
                    <div className={"col-xs-7 text-over c-blue"}>{this.state.user.contact_name}</div>
                    <div className={"clear"} />
                </div>
                <div className={"clear"} />
            </div>
        );
    };

} export default AccountData;