import React from "react";
import {AppData} from "./framework/bundle";
import "../css/Loading.css"

class Loading extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            loading_text: AppData.langs[props.lang].lLoading
        }
    }

    show(){
        window.$('#loading').modal('show');
    };

    hide(){
        window.$('#loading').modal('hide');
    };

    render() {
        return(
            <div className="modal fade" data-keyboard="false" tabIndex="-1" id={"loading"}>
                <div className={"loading"}>
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div><br/>
                    <div className={"loading-text"}>{this.state.loading_text}</div>
                </div>
                <div className={"loading"}>

                </div>
            </div>
        );
    }

} export default Loading
