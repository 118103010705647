import React from "react";
import BootstrapDataTimePicker from "../components/basic/BootstrapDataTimePicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {AppData, postRequest, showMsg} from "../components/framework/bundle";
//import RoadListReport_OLD from "./RoadListReport_OLD";

class Reports {

    constructor(props){
        this.data = {
            lang: props.lang,
            modal: props.modal,
            selectedDevice: props.selectedDevice,
            reportName: props.reportName,
            reportTitle: props.reportTitle,
            additionalData: props.additionalData,
            //reportAdditionalData: props.reportAdditionalData,


            //selectedDeviceInfo: props.selectedDeviceInfo,
            //id: uniqueId("modal-id"),
            //bodyId: uniqueId("modal-body"),
            //modalSizeClass: props.modalSizeClass || "", //modal-lg, modal-sm, modal-xl

            modalTitle: props.modalTitle || "",
            modalBody: props.modalBody || "",
            buttons: [],

            reportDTSRef: React.createRef(),
            reportDTERef: React.createRef(),

            pdfButton: React.createRef(),
            formRef: React.createRef()
        };
        //console.log(this.data);
        this.timer = null;
    }
    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
    };
    //Справка
    showReportIFrame=()=>{
        const l = this.data.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let modal = this.data.modal;

        let sts = window.moment(window.$("#report-datetimestart").data('DateTimePicker').viewDate()).format('X'); //start time stamp
        let ets = window.moment(window.$("#report-datetimeend").data('DateTimePicker').viewDate()).format('X'); //end time stamp
        let deviceID = this.data.selectedDevice;
        //let new_deviceID = this.data.new_deviceID;

        let iframe = (<iframe title={"IFrameReport"} src="" id={"iframe"} style={{border:"0"}} height={"100%"} width={"100%"} />);
        modal.setBody(<div className={"text-center"}><h1><strong><FontAwesomeIcon icon={["fa", "circle-notch"]} spin /> Зареждане ...</strong></h1></div>);

        let report_button_dom = window.document.getElementById("report-button")
        if (deviceID !== 0){
            let data = {sts:sts, ets:ets, deviceID: deviceID, lang:this.data.lang};

            Object.keys(this.data.additionalData).forEach((key)=>{
                data[key] = this.data.additionalData[key];
            });
            report_button_dom.disabled = true; //Disable button
            postRequest(this.data.reportName, data, (rd)=>{
                modal.setBodyStyle({overflow:"hidden"});
                modal.setBody(iframe);
                if ((rd.status === "success")) {
                    window.document.getElementById("iframe").src = AppData.config.report+rd.reportKey;//rd.url;
                    this.data.pdfButton.current.href = AppData.config.download+rd.reportKey;
                    this.data.pdfButton.current.removeAttribute("disabled");

                    report_button_dom.disabled = false; //Enable button
                }
                else{
                    report_button_dom.disabled = false; //Enable button
                    let msg = {type:"warning", title:q.lmw, text:q.msgReportNoEvents};
                    showMsg(msg);
                }
            }, ()=>{
                report_button_dom.disabled = false; //Enable button
            });
        }
        else {
            this.setState({tracksLoading: false});//Да спре показването на лоадването
            let msg = {type:"warning", title:q.lmw, text:q.mcd};
            showMsg(msg)
        }

        //this.setState({tracksLoading: true});//Да покаже лоадване
        //this.setState({tracks:[]},()=>{
        //});
    };
    //Хтмл в модал за справката
    prepareReport=()=>{
        const l = this.data.lang;
        const q = AppData.langs[l]; //Масива с езиците

        //let modal = this.data.modalRef.current;
        let modal = this.data.modal;

        const toolbar = (
            <div className={"modal-toolbar widget-body-toolbar"}>
                <div className={"col-sm-3 col-xs-6 plr5"}>
                    <BootstrapDataTimePicker
                        ref={this.data.reportDTSRef}
                        id={"report-datetimestart"}
                        date={window.moment().format('YYYYMMDD')+" 000000"}
                        lang={l}
                        addtionalClasses = {""}
                    />
                </div>
                <div className={"col-sm-3 col-xs-6 plr5"}>
                    <BootstrapDataTimePicker
                        ref={this.data.reportDTERef}
                        id={"report-datetimeend"}
                        date={window.moment().format('YYYYMMDD')+" 235959"}
                        lang={l}
                        addtionalClasses = {""}
                    />
                </div>
                <div className={"col-sm-6 col-xs-12 plr5"}>
                    <div className={"pull-left"}>
                        <button className={"btn btn-primary"} onClick={this.showReportIFrame} id={"report-button"}>
                            {q.lReport}
                        </button>
                    </div>
                    <div className={"pull-left btn-group ml5"}>
                        {/*
                        <button type="button" className="btn btn-default app-toolbar-button" onClick={()=>{this.showReport("pdf")}}>
                            <FontAwesomeIcon icon={["far", "file-pdf"]} size={"1x"} />
                        </button>
                        */}
                        <a href={null} ref={this.data.pdfButton} type="button" className="btn btn-default app-toolbar-button" disabled={"disabled"}>
                            <FontAwesomeIcon icon={["far", "file-pdf"]} size={"1x"} xlink={"http"} style={{pointerEvents: "none"}} />
                        </a>
                        {/*
                        <button type="button" className="btn btn-default app-toolbar-button" onClick={()=>{this.showReport("xls")}}>
                            <FontAwesomeIcon icon={["far", "file-excel"]} size={"1x"} />
                        </button>
                        */}
                    </div>
                </div>
                <div className={"clear"} />
            </div>
        );
        const body = (
            <div />
        );

        modal.setTitle(this.data.reportTitle);
        modal.setToolbar(toolbar,()=>{
            let reportDTS = this.data.reportDTSRef.current;
            let reportDTE = this.data.reportDTERef.current;
            reportDTS.initialize();
            reportDTE.initialize();
        });
        modal.setBody(body);
        //modal.setForm(form);
        modal.show();
    };

}
export default Reports;
