import React, {Component} from 'react';
import {AppData, customEvent, postRequest} from "../framework/bundle";
import BootstrapSelect from "../basic/BootstrapSelect";

/* Втори бокс Филтър за устройствата */
class GroupList extends Component{

    constructor(props){
        super(props);
        this.state = {
            lang: props.lang,
            groups: []
        }
    }

    setGroups=(groups)=>{
        this.setState({groups:groups})
    };

    /* При избор на групата от филтри */
    groupSelect=(val)=>{
        postRequest ("devicesByGroup", {groupID:val}, (responseData)=> {
            if (responseData.status === "success") {
                AppData.devices = responseData.devices;
                this.onGroupChange(AppData.devices);
            }
        });
    };

    onGroupChange=(dl)=>{
        this.setState({devices: dl}, ()=>{customEvent("mapShowDevices", {selectedDevice:0})});
    };

    render(){
        const p = this.props;
        const l = p.lang;
        const q = AppData.langs[l]; //Масива с езиците

        return(
            <div className={"filter-list"}>
                <div className={"title"}>
                    <h2>{q.titleFilter}</h2>
                </div>

                <div>
                    <BootstrapSelect
                        id={"group-list"}
                        options = {this.state.groups}//p.groups}
                        defaultValue = {0}
                        dataLiveSearch = {true}
                        addtionalClasses = {"mt5"}
                        onChangeCallback = {this.groupSelect}
                    />
                </div>

                {/*
                <div className={"mt5"} style={{textAlign:"center"}}>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""} >
                        <FontAwesomeIcon icon={['far', 'check-square']} />
                    </button>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""}>
                        <FontAwesomeIcon icon={['far', 'square']} />
                    </button>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""}>
                        <FontAwesomeIcon icon={"circle"} />
                    </button>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""}>
                        <FontAwesomeIcon icon={"circle"} />
                    </button>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""}>
                        <FontAwesomeIcon icon={"circle"} />
                    </button>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""}>
                        <FontAwesomeIcon icon={"circle"} />
                    </button>
                    <button className={"btn btn-default btn-circle filter-list-btn"} title={""}>
                        <FontAwesomeIcon icon={"circle"} />
                    </button>
                </div>
                */}
            </div>
        );
    };

} export default GroupList;