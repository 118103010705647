import React, {Component} from "react";

class BootstrapTable extends Component {

    constructor(props){
        super(props);

        let classes = "table table-striped table-hover table-condensed";
        if (props.classes) classes = props.classes;

        let headers = [];
        if (props.headers) headers = props.headers;

        let rows = [];
        if (props.rows) rows = props.rows;

        this.state = {
            classes: classes,
            headers: headers,
            rows: rows,
        };
    };

    setHeaders=(table_headers_array)=>{
        this.setState({headers: table_headers_array});
    };

    render() {
        let headers = this.state.headers.map((header, index)=>{
            return (<th key={index}>{header}</th>)
        });

        let rows = this.state.rows.map((row, index)=>{


            let tr = row.map((row_value, index)=>{
                return (<td key={index}>{row_value}</td>);
            });
            return (<tr key={index}>{tr}</tr>);
        });
        return(
        <table className={this.state.classes}>
            <thead>
                <tr>
                    {headers}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
        );
    }
} export default BootstrapTable;