import React, {Component, createRef} from 'react';
import {AppData, customEvent, postRequest, showMsg, timeDuration} from "../framework/bundle";
import BootstrapDataTimePicker from "../basic/BootstrapDataTimePicker";
import BootstrapSelect from "../basic/BootstrapSelect";
import TrackDataTrackList from "./TrackDataTrackList";
import TrackHandle from "../../core/items_handle/TrackHandle";

/* Първи бокс с описание на акунта */
class TrackDataTimeSelectBox extends Component{

    constructor(props){
        super(props);
        const q = AppData.langs[this.props.lang]; //Масива с езиците
        this.state = {
            //totalData: null,
            selectedDeviceOptions: null,
            deviceOptions: null
        };
        //this.info = null;
        this.myRefs = {
            dtsRef: createRef(),
            dteRef: createRef(),
        };
        this.timeChoice = [
            {value:0, name: q.lChoicePeriod},
            {value:1, name: q.lLast24},
            {value:2, name: q.lToday},
            {value:3, name: q.lYesterday},
            {value:4, name: q.lThisWeek},
            {value:5, name: q.lLastWeek},
            {value:6, name: q.lThisMonth},
            {value:7, name: q.lLastMonth},
        ];
        this.workSpace = props.workSpace;

        this.test = this.test.bind(this);
    }

    componentDidMount(){
        this.dateTimePickerInitialize();

        window.$(function () {
            window.$('[data-toggle="popover"]').popover()
        })
    };

    /*
    setTotalData=(totalData)=>{
        this.setState({totalData: totalData});
    };
    prepareDataDetails=()=>{
    };
    testPopover=()=>{
        //this.workSpace.myObjects.map.testPopover();
    };

    */

    setDeviceOptions=(deviceOptions)=>{
        this.setState({deviceOptions: deviceOptions});
    };

    //Избор на дата за траковете
    dateTimePickerInitialize=()=>{
        this.myRefs.dtsRef.current.initialize();
        this.myRefs.dteRef.current.initialize();
    };
    dataLoadByTime=()=>{
            //var worker = new Worker("workerfile.js");
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        //customEvent("mapClearTrack");
        this.workSpace.myObjects.map.mapClearData();

        let sts = window.moment(window.$("#trackDataTimeStart").data('DateTimePicker').viewDate()).format('X'); //start time stamp
        let ets = window.moment(window.$("#trackDataTimeEnd").data('DateTimePicker').viewDate()).format('X'); //end time stamp

        //let deviceID = this.myObjects.devicesList.state.selectedDevice; //this.props.ldr.current.state.selectedDevice;
        let deviceID = this.workSpace.myObjects.devicesList.state.selectedDevice; //this.props.ldr.current.state.selectedDevice;

        //TODO loading
        if (deviceID !== -1){
            if (ets-sts > AppData.config.reportPeriod) {
                //One month period
                let msg = {type:"warning", title:q.lmw, text:q.mOneMonthLimit};
                showMsg(msg);
            }
            else {
                //show Loading
                this.workSpace.myObjects.loading.show();

                let data = {sts:sts, ets:ets, deviceID: deviceID, lang:l};
                postRequest("get-track-data-by-time", data, (rd)=>{
                    //console.log(rd.tracks);
                    if ((rd.data.length > 0) && (rd.status === "success")) {
                        //Подготовка на данните

                        //Зареждане на данни
                        //console.log(rd.tracks);

                        //Set data
                        //Set tracks
                        let track_handle = new TrackHandle();
                        let track_list = track_handle.tracksArrayToTracksArrayObjects(rd.tracks);

                        //Set State data tracks
                        this.workSpace.setState({data: rd.data, tracks: track_list, total_data: rd.total}, ()=>{
                            //callback after set track & data state
                            //Ако е избрана опцията track only да не се изчертават всички данни
                            if(!this.workSpace.options.track_only) this.workSpace.myObjects.map.mapDrawData(rd);
                            this.workSpace.myObjects.downDrawer.refTrackDataCounterInfo.current.setCounter(rd.data.length);
                            //this.workSpace.myObjects.trackDataTrackList.setTracks(rd.track_data_status_change);//Масива с данни разделени по статус, стоп, паркинг, движение
                            //this.setState({totalData: rd.total});
                            //this.workSpace.setTotalData(rd.total);
                            this.workSpace.myObjects.trackDataDetailsBox.setTrackDataDetails(rd.data);
                        });

                        //TODO loading
                    }
                    else {
                        let msg;
                        //this.myObjects.tracksListBox.setTracksLoading(false);//Да спре показването на лоадването
                        if (rd.status === "no-data") msg = {type:"warning", title:q.lmw, text:q.mntr};
                        if (rd.status === "one-month-limit") msg = {type:"warning", title:q.lmw, text:q.mOneMonthLimit};
                        showMsg(msg);
                    }
                    this.workSpace.myObjects.loading.hide();
                });
            }
        }
        else {
            //this.myObjects.tracksListBox.setTracksLoading(false);
            let msg = {type:"warning", title:q.lmw, text:q.mcd};
            showMsg(msg)
        }

    };
    timePeriodSelect=(val)=>{
        switch (val){
            default:
                break;
            case "1":
                //Last 24 hours
                this.myRefs.dtsRef.current.setDateTime(window.moment().subtract(1, 'days'));
                this.myRefs.dteRef.current.setDateTime(window.moment());
                break;

            case "2":
                //Today
                this.myRefs.dtsRef.current.setDateTime(window.moment().format('YYYYMMDD')+" 000000");
                this.myRefs.dteRef.current.setDateTime(window.moment().format('YYYYMMDD')+" 235959");
                break;
            case "3":
                //Yesterday
                this.myRefs.dtsRef.current.setDateTime(window.moment().format('YYYYMMDD')-"000001"+" 000000");
                this.myRefs.dteRef.current.setDateTime(window.moment().format('YYYYMMDD')-"000001"+" 235959");
                break;
            case "4":
                //This week
                this.myRefs.dtsRef.current.setDateTime(window.moment().startOf('week'));
                this.myRefs.dteRef.current.setDateTime(window.moment().endOf('week'));
                break;
            case "5":
                //Last week
                this.myRefs.dtsRef.current.setDateTime(window.moment().subtract(1, 'weeks').startOf('week'));
                this.myRefs.dteRef.current.setDateTime(window.moment().subtract(1, 'weeks').endOf('week'));
                break;
            case "6":
                //This month
                this.myRefs.dtsRef.current.setDateTime(window.moment().startOf('month'));
                this.myRefs.dteRef.current.setDateTime(window.moment().endOf('month'));
                break;
            case "7":
                //Last month
                this.myRefs.dtsRef.current.setDateTime(window.moment().subtract(1, 'month').startOf('month'));
                this.myRefs.dteRef.current.setDateTime(window.moment().subtract(1, 'month').endOf('month'));
                break;
        }
    };

    handleOptionTrackOnly=()=>{
        this.workSpace.options.track_only = !this.workSpace.options.track_only;
    };

    async test(){
        let i;
        for (i=0;i<3000000000;i++){
            let p = i /2 *4;
            if ( i % 100000 === 0) p = i /2 *4;//console.log(i);
        }
        console.log(this);
    }

    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let infoBox = "";
        if (this.props.total_data != null){
            infoBox = (
            <div className={"fs12"}>
                <label>{q.lInformation}</label><br />
                <div className={"row"}>
                    <div className={"col-xs-7"}>{q.lMovementDistance}</div>
                    <div className={"col-xs-5 text-right"}><strong>{this.props.total_data.distance/1000}</strong> km</div>
                </div>
                <div className={"row"}>
                    <div className={"col-xs-7"}>{q.lMovementTime}</div>
                    <div className={"col-xs-5 text-right"}><strong>{timeDuration(this.props.total_data.movingTime)}</strong> h</div>
                </div>
                <div className={"row"}>
                    <div className={"col-xs-7"}>{q.lStopTime}</div>
                    <div className={"col-xs-5 text-right"}><strong>{timeDuration(this.props.total_data.stopTime)}</strong> h</div>
                </div>
                <div className={"row"}>
                    <div className={"col-xs-7"}>{q.lParkTime}</div>
                    <div className={"col-xs-5 text-right"}><strong>{timeDuration(this.props.total_data.parkTime)}</strong> h</div>
                </div>
            </div>
            );
        }

        const option_track_only = this.workSpace.options.track_only;
        return(
            <div className={"col-xs-12"}>
                <div className={"panel panel-default"} >
                    <div className={"panelBody padding-5"} id={"panelDataTimeSelect"}>
                        <div className={"row"}>
                            <div className={"col-xs-2 "}>
                                <label>{q.lStartDate}</label>
                                <BootstrapDataTimePicker
                                    id={"trackDataTimeStart"}
                                    ref={this.myRefs.dtsRef}
                                    date={window.moment().format('YYYYMMDD')-"000000"+" 000000"}
                                    lang={l}
                                    addtionalClasses = {""}
                                />
                                <label>{q.lEndDate}</label>
                                <BootstrapDataTimePicker
                                    id={"trackDataTimeEnd"}
                                    ref={this.myRefs.dteRef}
                                    date={window.moment().format('YYYYMMDD')+" 235959"}
                                    lang={l}
                                    addtionalClasses = {""}
                                />
                                <button
                                    onClick={this.dataLoadByTime}
                                    className={"btn btn-success"}
                                >{q.lLoad}
                                </button>
                            </div>
                            <div className={"col-xs-2 no-left-padding "}>
                                <label>{q.lPeriod}</label>
                                <BootstrapSelect
                                    id={"time-select"}
                                    options = {this.timeChoice}//p.groups}
                                    defaultValue = {0}
                                    dataLiveSearch = {false}
                                    onChangeCallback = {(val)=>{this.timePeriodSelect(val)}}
                                />
                                <div className={"mt5"}>
                                    <label>Допълнителни</label><br />

                                    <div className="form-check abc-checkbox abc-checkbox-primary checkbox-fix">
                                        <input className="form-check-input" id={"track-only-inputs"} type="checkbox"
                                               data-itemtype="device-checkbox"
                                               onInput={this.handleOptionTrackOnly}
                                               defaultChecked={option_track_only}
                                        />
                                        <label className="form-check-label" htmlFor={"track-only-inputs"} >Показвай само тракове</label>
                                    </div>
                                </div>
                                {/*
                                <label className={"mt5"}>{q.lAdditional}</label>
                                <BootstrapSelect
                                    id={"additional-select"}
                                    options = {this.timeChoice}//p.groups}
                                    defaultValue = {0}
                                    dataLiveSearch = {false}
                                />
                                */}
                            </div>
                            <TrackDataTrackList
                                ref={this.workSpace.myRefs.trackDataTrackList}
                                workSpace={this.workSpace}
                                lang={l}

                                tracks={this.props.tracks}
                                data={this.props.data}
                            />
                            <div className={"col-xs-3"}>
                                {infoBox}
                            </div>
                        </div>
                        {/*
                        <button
                            onClick={this.testPopover}
                            className={"btn btn-warning"}
                        >TEST
                        </button>

                        <a tabIndex="0" className="btn btn-danger" role="button" data-toggle="popover"
                           title="Title"
                           data-content="And here's some amazing content. It's very engaging. Right?">Dismissible popover</a>
                        */}
                    </div>
                </div>
            </div>
        );
    };

} export default TrackDataTimeSelectBox;
