import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {AppData, customEvent} from "../framework/bundle";

class TrackDataDetailsBox extends Component {

    constructor(props){
        super(props);
        this.state = {
            trackDataDetails: []
        };
        this.mobileyeData = {};
        this.table = null;
        this.workSpace = props.workSpace;
        //this.clearMobileyeData();
    }

    componentDidUpdate(){
        //UPDATE на мобилай таб след като е сметнато вече в този клас
        this.workSpace.myObjects.trackDataDetailsMobileye.setTrackDataDetails(this.mobileyeData);
    };

    setTrackDataDetails=(trackDataDetails)=>{
        this.setState({trackDataDetails:trackDataDetails},()=>{
            this.initialize();
        });


        //this.setState({trackDetails:trackDetails},()=>{

        //});
    };
    clearMobileyeData=()=>{
        this.mobileyeData = {
            pdz:0,
            pfcw:0,
            lldw:0,
            rldw:0,
            hwl1:0,
            hwl2:0,
            sw1:0, sw2:0, sw3:0, sw4:0, sw5:0, sw6:0, sw7:0,
            ta:0
        }
    };

    initialize=()=>{
        window.$(function () {
            window.$('[data-toggle="popover"]').popover();
        });

    };

    movementStatus=(row)=>{
        let movementStatus = 0;
        if ((row.engine_status === "0") && (row.speed === 0.00)) movementStatus = 1;//Engine OFF speed 0, idle
        else if (row.speed === 0.00) movementStatus = 2;//Engine ON, not moving, stop
        else if (row.speed > 0 ) movementStatus = 3;//Engine ON, mooving

        return movementStatus;
    };

    dataRowClick=(i)=>{
        let dataRow = this.state.trackDataDetails[i];
        this.workSpace.myObjects.map.popoverShowDataDetails(dataRow);
    };

    trackDataDetailsClear=()=>{
        //this.setState({tracks:[]});
        //this.setState({selectedTrack:null});
        //Изчистване на детайлите

        //if (this.myObjects.downDrawer.TrackCounterInfo.state.counter !== 0) this.myObjects.downDrawer.TrackCounterInfo.setCounter(0);
        //if (this.myObjects.trackInfoBox.state.trackInfo !== null) this.myObjects.trackInfoBox.setTrackInfo(null);
        //if (this.myObjects.trackDetailsTable.state.trackDetails.length > 0) this.myObjects.trackDetailsTable.setTrackDetails([]);
        //if (this.myObjects.trackDetailsMobileye.state.trackDetailsMobileye !== null) this.myObjects.trackDetailsMobileye.setTrackDetails(null);

        this.setState({trackDataDetails:[]});
        let element = document.getElementById('popup');
        window.$(element).popover('destroy');
    };

    render(){
        //TODO При клик върху девайс се рендва! Защо?
        const p = this.props;
        //const l = p.lang;
        //const q = AppData.langs[l]; //Масива с езиците
        this.clearMobileyeData();
        //let first = true, movementStatus;
        let movementStatus;
        let totalDistance;
        //let parkTime = 0, stopTime = 0,  movementTime = 0;
        let rows = null;

        if (this.state.trackDataDetails.length > 0){
            rows = this.state.trackDataDetails.map((td,i)=>{

                //if (td.can_speed > 0) console.log(td);
                //if (td.can_speed > 0) console.log(i);

                let tableClass = "";
                let mobileyeIcon;
                let mobileyePopover;

                totalDistance += td.distance;
                // Info and classes
                movementStatus = this.movementStatus(td);

                switch (movementStatus){
                    case 1:
                        tableClass="engine-off";
                        break;
                    case 2:
                        tableClass="stoped";
                        break;
                    case 3:
                        tableClass="movement";
                        break;

                    default:
                        break;
                }

                //TODO Да се преработи за сега никаде в таблицата не се показват евенти за Мобилай
                if (td.mobileye_pedestrian_in_DZ !== undefined){
                    if ((td.mobileye_pedestrian_in_DZ !== 0) ||
                        (td.mobileye_pedestrian_in_FCW !== 0) ||
                        (td.mobileye_left_LDW !== 0) ||
                        (td.mobileye_right_LDW !== 0) ||
                        (td.mobileye_headway_warning_level !== 0) ||
                        (td.mobileye_tamper_allert !== 0) ||
                        (td.mobileye_TSR_speed_warning_level !== 0)){
                        let popoverRow = "", popoverTable;
                        if (td.mobileye_pedestrian_in_DZ === 1) {
                            popoverRow += '<tr><td><img src="img/icon32/peadestrantDZ.png" /></td><td>Pedestrian & Cyclist Collision Warning - Danger zone</td></tr>';
                            this.mobileyeData.pdz++;
                        }
                        if (td.mobileye_pedestrian_in_FCW === 1) {
                            popoverRow += '<tr><td><img src="img/icon32/peadestriant.png" /></td><td>Pedestrian & Cyclist Collision Warning - Front colision warning</td></tr>';
                            this.mobileyeData.pfcw++;
                        }
                        if (td.mobileye_left_LDW === 1) {
                            popoverRow += '<tr><td><img src="img/icon32/ldw.png" /></td><td>Left line departure warning</td></tr>';
                            this.mobileyeData.lldw++;
                        }
                        if (td.mobileye_right_LDW === 1) {
                            popoverRow += '<tr><td><img src="img/icon32/ldw.png" /></td><td>Right line departure warning</td></tr>';
                            this.mobileyeData.rldw++;
                        }
                        if (td.mobileye_headway_warning_level === 1) {
                            popoverRow += '<tr><td><img src="img/icon32/fcw1.png" /></td><td>Headway Monitoring Warning (safe headway)</td></tr>';
                            this.mobileyeData.hwl1++;
                        }
                        if (td.mobileye_headway_warning_level === 2) {
                            popoverRow += '<tr><td><img src="img/icon32/fcw2.png" /></td><td>Headway Monitoring Warning (unsafe headway)</td></tr>';
                            this.mobileyeData.hwl2++;
                        }
                        let speedWarning;
                        switch (td.mobileye_TSR_speed_warning_level) {
                            case 1: speedWarning = "from 0 to 5"; this.mobileyeData.sw1++; break;
                            case 2: speedWarning = "from 5 to 10"; this.mobileyeData.sw2++; break;
                            case 3: speedWarning = "from 10 to 15"; this.mobileyeData.sw3++; break;
                            case 4: speedWarning = "from 15 to 20"; this.mobileyeData.sw4++; break;
                            case 5: speedWarning = "from 20 to 25"; this.mobileyeData.sw5++; break;
                            case 6: speedWarning = "from 25 to 30"; this.mobileyeData.sw6++; break;
                            case 7: speedWarning = "over 30"; this.mobileyeData.sw7++; break;
                            default:
                                break;
                        }
                        if (td.mobileye_TSR_speed_warning_level >= 1) popoverRow += '<tr><td><img src="img/icon32/speed_limit.png" /></td><td>Speed Limit Indication <br /> Overspeed '+speedWarning+' km/h</td></tr></div>';
                        //if (td.mobileye_tamper_allert === 1) popoverRow += '<img src="img/icon32/fcw2.png" />';

                        popoverTable = '' +
                            '<table class="table-striped table-bordered table-condensed">' +
                            '   <thead>' +
                            '       <tr>' +
                            '           <th>Event</th>' +
                            '           <th>Data</th>' +
                            '       </tr>' +
                            '   </thead>' +
                            '   <tbody>' +
                            popoverRow+
                            '   </tbody>' +
                            '</table>';

                        mobileyeIcon = (
                            <img
                                className={"mobileye-details-column-img"}
                                src={"img/icon32/mobileye.png"}
                                data-toggle="popover"
                                data-placement="top"
                                data-trigger="hover"
                                data-html="true"
                                title={"<b>Mobileye event</b>"}
                                data-content={"<div>"+popoverTable+"</div>"}
                                alt={"mobileye"}
                            />)
                    }

                    mobileyePopover = (<div className={"mobileye-details-column"}>{mobileyeIcon}</div>);

                }
                else {
                    mobileyePopover = (<div />);
                }

                //ManualCan
                //Ако има включен manual can
                //if(this.workSpace.myObjects.devicesList.state.selectedDeviceInfo.device_options.manual_can === "1")


                //Send to TrackDataTimeSelect
                let totalData = {
                    totalDistance: totalDistance,
                };
                //this.workSpace.myObjects.trackDataTimeSelectBox.setTotalData(totalData);

                return (
                    <tr key={"track-data-"+i} className={tableClass} onClick={()=>{this.dataRowClick(i)}}>
                        <td className={"tc"}>{i+1}</td>
                        <td className={"tc"}>{td.timestampConv}</td>
                        <td className={"tc"}>{td.lat} / {td.lng}</td>
                        <td className={"tc"}>{td.distance} m</td>
                        <td className={"tr"}>{td.speed} km/h</td>
                        <td className={"tc"}><FontAwesomeIcon icon={"arrow-up"} style={{transform:"rotate("+td.angle+"deg)"}}/></td>
                        <td className={"tr"}>{td.alt} m</td>
                        <td className={"tr"}>{td.sat}</td>
                        <td className={"tr"}>{td.external_power_voltage/1000} V</td>
                        <td className={"tr"}>{td.internal_battery_voltage/1000} V</td>
                        <td className={"tr"}>{td.analog_1/1000} V</td>
                        {/*
                        <td className={"tr"}>{td.analog_2/1000} V</td>
                        <td className={"tr"}>{td.analog_3/1000} V</td>
                        <td className={"tr"}>{td.analog_4/1000} V</td>
                        */}
                    </tr>
                );
            });
            //Ако има данни
        }
        else {
            //Ако няма данни
        }
        /*
        <th className={"tc"}>Mobileye event</th>
        <td className={"tc"}>{mobileyePopover}</td>
         */
        return (
            <div id={"panelTrackDetails"} className={"panel panel-track-details"}>
                <table id={`${p.id}`} className="table table-condensed" width="100%">
                    <thead>
                    <tr>
                        <th className={"tc"}>#</th>
                        <th className={"tc"}>Time</th>
                        <th className={"tc"}>Coordinates</th>
                        <th className={"tc"}>Distance</th>
                        <th className={"tc"}>Gps speed</th>
                        <th className={"tc"}>Angle</th>
                        <th className={"tc"}>Alt</th>
                        <th className={"tc"}>Sat</th>
                        <th className={"tc"}>EPV</th>
                        <th className={"tc"}>IBV</th>
                        <th className={"tr"}>A1</th>
                        {/*
                        <th className={"tr"}>A2</th>
                        <th className={"tr"}>A3</th>
                        <th className={"tr"}>A4</th>
                        */}
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default TrackDataDetailsBox;
