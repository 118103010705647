import React, {Component} from "react";
import {AppData} from "../framework/bundle";

class TrackDataDetailsMobileye extends Component {

    constructor(){
        super();

        this.state = {
            //trackDetailsData: false,
            trackDataDetailsMobileye: null
        }
    };

    setTrackDataDetails=(trackDataDetailsMobileye)=>{
        this.setState({trackDataDetailsMobileye:trackDataDetailsMobileye});
    };


    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        if (this.state.trackDataDetailsMobileye === null){
            return (
                <div id={"panel5"} className={"panel panel-track-details padding-5"}>
                    <div className="alert alert-info">
                        <strong>{q.lmi}</strong><br /> {q.tscourse}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id={"panel5"} className={"panel panel-track-details"}>
                    <table className={"table table-striped table-condensed"}>
                        <thead>
                            <tr>
                                <th>Mobileye event</th>
                                <th>Event description</th>
                                <th>Number of events</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/peadestrantDZ.png"} alt={"pcw"} /></td>
                                <td>Pedestrian & Cyclist Collision Warning - Front colision warning</td>
                                <td>{this.state.trackDataDetailsMobileye.pfcw}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/fcw2.png"} alt={"fcw2"} /></td>
                                <td>Forward Collision Warning (unsafe headway)</td>
                                <td>{this.state.trackDataDetailsMobileye.hwl2}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/ldw.png"} alt={"lldw"} /></td>
                                <td>Left line departure warning</td>
                                <td>{this.state.trackDataDetailsMobileye.lldw}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/ldw.png"} alt={"rldw"} /></td>
                                <td>Right line departure warning</td>
                                <td>{this.state.trackDataDetailsMobileye.rldw}</td>
                            </tr>

                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl-over30"} /></td>
                                <td>Speed Limit Indication. Overspeed over 30 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw7}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl30"}/></td>
                                <td>Speed Limit Indication. Overspeed from 25 to 30 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw6}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl25"} /></td>
                                <td>Speed Limit Indication. Overspeed from 20 to 25 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw5}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl20"} /></td>
                                <td>Speed Limit Indication. Overspeed from 15 to 20 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw4}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl15"} /></td>
                                <td>Speed Limit Indication. Overspeed from 10 to 15 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw3}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl10"} /></td>
                                <td>Speed Limit Indication. Overspeed from 5 to 10 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw2}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/speed_limit.png"} alt={"sl10"} /></td>
                                <td>Speed Limit Indication. Overspeed from 0 to 5 km/h</td>
                                <td>{this.state.trackDataDetailsMobileye.sw1}</td>
                            </tr>

                            <tr>
                                <td className={"tc"}><img src={"img/icon32/peadestrant.png"} alt={"pdz"} /></td>
                                <td>Pedestrian & Cyclist Collision Warning - Danger zone</td>
                                <td>{this.state.trackDataDetailsMobileye.pdz}</td>
                            </tr>
                            <tr>
                                <td className={"tc"}><img src={"img/icon32/fcw1.png"} alt={"fcw1"} /></td>
                                <td>Forward Collision Warning (safe headway)</td>
                                <td>{this.state.trackDataDetailsMobileye.hwl1}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            );
        }
    }
}
export default TrackDataDetailsMobileye;