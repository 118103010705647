import React, {Component} from "react";

class BootstrapSelect extends Component {

    componentDidMount(){
        const p = this.props;
        const __this = this;
        window.$("#"+p.id).selectpicker();
        window.$("#"+p.id).on("change", function() {
            let val = window.$("#"+p.id).val();
            __this.handleSelect(val);
        });
        /*
            Change even not occur after select same value
         */
        window.$("button[data-id='"+p.id+"']").on("click", function() {
            window.$("#"+p.id).val(-1);
        });
    };

    componentDidUpdate(){
        const p = this.props;
        window.$("#"+p.id).selectpicker("refresh");
    };

    handleSelect=(val)=>{
        if (typeof this.props.onChangeCallback === "function")
        this.props.onChangeCallback(val);
    };

    render(){
        const p = this.props;
        const dw = p.dw || "100%";
        const dv = p.defaultValue || null;
        const ac = p.addtionalClasses || "";
        const dls = p.dataLiveSearch || false;

        const options = p.options.map((option, i) => {
            this.s = "";
            if (option.selected) this.s = "selected";
            return (<option value={option.value} key={i}>{option.name}</option>)
        });

        return (
            <select
                id={p.id}
                defaultValue={dv}
                className={`${ac}`}
                data-width={dw}
                data-live-search={dls}
                //onInput={this.handleSelect}
            >
                {options}
            </select>
        );
    }
}
export default BootstrapSelect;
