import React from "react";
import {AppData} from "../framework/bundle";


//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import { AppData, customEvent, findParentDataTag, positiveButtons, postRequest, showMsg, showMsgSmart } from "../framework/bundle";
//import { toLonLat, fromLonLat} from 'ol/proj.js';

class Accounts {

    //TODO Изтрий защото е в парент клас
    /*
    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
    };
    */
    constructor(lang, myObjects) {
        //super();
        this.lang = lang;
        this.myObjects = myObjects;
    }

    showModal=()=>{
        const l = this.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let modal = this.myObjects.modalWindow;

        let modalBody = (
            <div className={"row"}>
                <div className={"col-xs-12"}>
                    /* Toolbar */
                    <div className="modal-toolbar widget-body-toolbar">
                        <div className={"col-xs-4"}>

                        </div>
                    </div>
                    /* Toolbar */
                    <h2>{q.sysMenuTextAccounts}</h2>
                </div>
            </div>
        );

        let modalButtons = (
            <button type="button" className="btn btn-default" data-dismiss="modal">{q.lClose}</button>
        );

        modal.setClass("modal-xl");
        modal.setTitle(q.sysMenuTextAccounts);
        modal.setBody(modalBody);
        modal.setButtons(modalButtons);
        modal.show();

        //this.myObjects.modalWindow.show();
        //let modal = this.myObjects
    };


} export default Accounts

//TODO editing
//http://dev.openlayers.org/examples/modify-feature.html
//TODO delete При изтриване на зоната да се изтрие и от полето
//TODO delete Като смини таба също да се изтриват
//TODO view Може да има един лейър за вскички зони

