import React, {Component} from "react";

import '../../css/AppMenu.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppData} from "../framework/bundle";
import UsersModal from "../../core/modals/UsersModal";
import Accounts from "../accounts/Accounts";
//import {AppData, customEvent, uniqueId} from "../framework/bundle";

class AppMenu extends Component {

    constructor(props){
        super(props);

        this.workSpace = props.workSpace;
        const l = props.lang;
        const q = AppData.langs[l]; //Масива с езиците
        /*
        this.state = {
            id: props.id,
            modalHeader: props.modalHeader || "",
            modalBody: props.modalBody || "",
            modalFooter: props.modalFooter || "",
        }
        */

        //Fixed AppMenu can be done as params
        let menuHeader = (<div className={"modal-header"}>System menu</div>);

        let menuBody = (
        <div className="modal-body">
            <button onClick={this.openAccountsDialog}>{q.sysMenuTextAccounts}</button>
            <button onClick={this.openUsersDialog}>{q.sysMenuTextUsers}</button>
        </div>);
        let menuFooter = null;
        this.state =  {
            id: "sys-menu",
            lang: props.lang,
            menuHeader: menuHeader,
            menuBody: menuBody,
            menuFooter: menuFooter,
        }
    }

    //Fixed AppMenu can be done as params
    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
        //TODO REMOVE!!!
    };

    showAppMenu=()=>{
        window.$(`#${this.state.id}`).modal('show');
    };
    hideAppMenu=()=>{
        window.$(`#${this.state.id}`).modal('hide');
    };

    openAccountsDialog=()=>{
        this.hideAppMenu();

        let accounts = new Accounts(this.state.lang, this.myObjects);
        accounts.showModal();
    };
    openUsersDialog=()=>{
        this.hideAppMenu();

        let users_modal = new UsersModal(this.state.lang, this.workSpace);
        users_modal.showModal();
    };
    /*
        //html

        setHeader=(html)=>{
            let content = (
                <div className="modal-header">
                    {html}
                </div>
            );
            this.setState({menuHeader: content});
        };
        setBody=(html)=>{
            let content = (
                <div className="modal-body">
                    {html}
                </div>
            );
            this.setState({menuBody: content});
        };
        setFooter=(html)=>{
            let content = (
                <div className="modal-footer">
                    {html}
                </div>
            );
            this.setState({menuFooter: content});
        };
        */

    render(){
        return (
            <div className="sys-menu modal fade" id={this.state.id} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-right modal-sm">
                    <div className="modal-content sys-menu-content">
                        <div className={"sys-menu-close"}>
                            <button className="btn btn-default btn-sm header-button"
                                data-dismiss={"modal"}>
                                <FontAwesomeIcon icon="times" />
                            </button>
                        </div>
                        {this.state.menuHeader}
                        {this.state.menuBody}
                        {this.state.menuFooter}
                    </div>
                </div>
            </div>
        );
    }
}
export default AppMenu;