class Track {

    id = null;
    status = null;
    start_id = null;
    end_id = null;
    total_time = 0;
    stop_time = 0;
    stop_count = 0;
    distance = 0;

    setID(id){
        this.id = parseInt(id);
    };
    getID(){
        return this.id
    }

    setStatus(status){
        this.status = parseInt(status);
    };
    getStatus(){
        return this.status
    }

    setStartID(id){
        this.start_id = parseInt(id);
    }
    getStartID(){
        return this.start_id;
    }

    setEndID(id){
        this.end_id = parseInt(id);
    }
    getEndID(){
        return this.end_id;
    }

    setTotalTime(seconds){
        this.total_time = parseInt(seconds);
    }
    getTotalTime(){
        return this.total_time;
    }

    setStopTime(seconds){
        this.stop_time = parseInt(seconds);
    }
    getStopTime(){
        return this.stop_time;
    }

    setStopCount(count){
        this.stop_count = parseInt(count);
    }
    getStopCount(){
        return this.stop_count;
    }

    setDistance(meters){
        this.distance = parseInt(meters);
    }
    getDistance(){
        return this.distance;
    }
} export default Track
