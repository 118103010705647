import React, {Component, createRef} from 'react';
import {AppData} from "./framework/bundle";

import TrackDataTimeSelectBox from "./down_drawer/TrackDataTimeSelectBox";
import TrackDataDetailsBox from "./down_drawer/TrackDataDetailsBox";
import TrackDataDetailsMobileye from "./down_drawer/TrackDataDetailsMobileye";

class DownDrawer extends Component{
//https://eonasdan.github.io/bootstrap-datetimepicker/Installing/#manual -
    constructor(props){
        super(props);
        this.refTrackDataCounterInfo = createRef();
        this.workSpace = this.props.workSpace;
    }
    componentDidMount(){
        this.TrackDataCounterInfo = this.refTrackDataCounterInfo.current;
    };

    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
    };

    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците


        return(
            <div className={"down-drawer"} id={"down-drawer"}>
                <div className={"title"}><h2>{q.tDawnDrawer}</h2></div>

            <div className={"widget-body mt5"} >
                <ul id="myTab1" className={"nav nav-tabs bordered"}>
                    <li className={"active"}>
                        <a href={"#trackDataTimeSelect"} data-toggle="tab" aria-expanded="false">{q.lMovements}</a>
                    </li>
                    <li>
                        <a href={"#trackDataDetailsBox"} data-toggle="tab" aria-expanded="true">{q.lTrackDataDetails}
                            <TrackDataCounterInfo
                                ref={this.refTrackDataCounterInfo}
                                workSpace={this.workSpace}
                            />
                        </a>
                    </li>
                    <li>
                        <a href={"#track-details-mobileye"} data-toggle="tab" aria-expanded="true">
                            <img src={"img/icon32/mobileye.png"} style={{height:"16px"}} alt={"mobileye"} /> {q.lMobileyeTrackDetails}
                        </a>
                    </li>
                </ul>

                <div className={"tab-content padding-10"} id={"down-panel-content"}>

                    <div className="tab-pane fade in active" id="trackDataTimeSelect">
                        <div className={"row"} >
                            <TrackDataTimeSelectBox
                                ref={this.props.myRefs.trackDataTimeSelectBox}
                                workSpace={this.workSpace}
                                lang={l}

                                tracks={this.props.tracks}
                                data={this.props.data}
                                total_data={this.props.total_data}
                                //myRefs={this.props.myRefs}
                            />
                        </div>
                    </div>

                    <div className="tab-pane fade" id="trackDataDetailsBox" >
                        <TrackDataDetailsBox
                            ref={this.props.myRefs.trackDataDetailsBox}
                            workSpace={this.workSpace}
                            lang={l}

                            //id={"track-details-table"}
                        />
                    </div>

                    <div className="tab-pane fade" id="track-details-mobileye" >
                        <TrackDataDetailsMobileye
                            ref={this.props.myRefs.trackDataDetailsMobileye}
                            lang={l}
                        />
                    </div>

                </div>
            </div>

            </div>
        );
    }

} export default DownDrawer

class TrackDataCounterInfo extends Component{

    constructor(props){
        super(props);
        this.state = {
            trackDataDetailsCount: 0,
        };
        this.workspace = this.props.workSpace;
    };

    setCounter=(trackDataDetailsCount)=>{
        this.setState({trackDataDetailsCount:trackDataDetailsCount});
    };

    render(){
        return(
            <span className={"badge bg-color-blue txt-color-white"}>
                {this.state.trackDataDetailsCount}
            </span>
        );
    }
}
