import React, {Component} from 'react';
import {AppData, findParentDataTag} from "../framework/bundle";

class TrackDataTrackList extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedTrack: null,
            tracksLoading: false,
        };
        this.workSpace = props.workSpace;
    }

    setTracks=(tracks)=>{
        this.setState({tracks: tracks});
    };

    selectTrack=(i)=>{
        this.workSpace.myObjects.map.mapDrawTrack(this.props.data, this.props.tracks[i]);
    };

    render() {
        //console.log(this.workSpace);
        const p = this.props;
        const l = p.lang;
        const q = AppData.langs[l]; //Масива с езиците
        let trackListContent;

        //TODO Рендва два пъти
        //console.log(this.props.data);
        //console.log(this.props.tracks);

        if (this.props.tracks.length === 0){
            trackListContent = (
                <div />
            );
        }
        else {
            let trackList = "";
            if (this.props.tracks){
                let trackListLI = this.props.tracks.map((track, i)=>{
                    //let st = this.trackFromToString(track);
                    if ( (track.status === 1) && (track.distance > 0) ){
                        return (
                            <li
                                key={"trackList-"+i}
                                onClick={()=>{
                                    this.selectTrack(i);
                                    //this.workSpace.myObjects.map.mapDrawTrack(this.props.data, this.props.tracks[i]);
                                }}
                            >
                                {this.props.data[track.getStartID()].timestampConv} - {this.props.data[track.getEndID()].timestampConv} <b>({(track.getDistance()/1000).toFixed(3)} km)</b>
                            </li>
                        )
                    }

                });
                trackList = (
                        <ul>
                            {trackListLI}
                        </ul>
                );
            }

            trackListContent = (trackList);
        }

        return (
            <div className={"col-xs-4 no-padding"}>
                <label>{q.lTracks}</label>
                <div className={"track-list"} id={"track-list-container"}>
                    {trackListContent}
                </div>
            </div>
        );
    }

} export default TrackDataTrackList
