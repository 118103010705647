import React from 'react';
import LabeledInput from "./basic/LabeledInput";
import {AppData, postRequest, showMsg} from "./framework/bundle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Login extends React.Component {
    //TODO След ънлогин и веднага логин дава грешка
    constructor(props){
        super(props);
        this.state = {
            buttonLoading : false,
            accountname: "",
            username: "",
            password: "",
            lang: AppData.config.defaultLangr
        };
    }


    //Parent callback
    callbackLabeledInputOnChange = (callbackData) => {
        this.setState ({[callbackData.key]:callbackData.value});
    };
    //Child callback
    handleOnLogin = (e) => {
        e.preventDefault();
        this.setState({buttonLoading: true},()=>{
            this.tryLogin();
        });
    };
    tryLogin=()=> {
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let emptyMsg = {type:"warning", title:q.lmw, text:q.mlf};
        let data = this.state;

        //if (false){
        //if ( (data.accountname === "") || (data.username === "") || (data.password === "") ){
        if ( (data.username === "") || (data.password === "") ){
            this.setState({buttonLoading: false},()=>{showMsg(emptyMsg)});
        }
        else {
            let loginData = {
                //accountname: this.state.accountname,
                username: this.state.username,
                password: this.state.password
            };

            postRequest("login", loginData,
                (responseData)=>{this.setState({buttonLoading: false}, ()=>{this.checkLogin(responseData);})},
                ()=> this.setState({buttonLoading:false}))


        }
    };
    checkLogin=(responseData)=>{
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        if (responseData['status'] === 'success') {
            this.props.callbackOnLogin();
        }
        else {
            let noUserMsg = {type:"warning", title:q.lmw, text:q.mlw};
            let userDisabledMsg = {type:"warning", title:q.lmw, text:q.mlud};
            let accountDisabledMsg = {type:"warning", title:q.lmw, text:q.mlad};

            switch (responseData['reason']) {
                case 1:
                    showMsg(userDisabledMsg);
                    break;
                case 2:
                    showMsg(accountDisabledMsg);
                    break;
                default:
                    showMsg(noUserMsg);
                    break;
            }
        }


    };

    submitButton = () => {
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        if (this.state.buttonLoading){
            return (
                <button className="btn btn-primary" disabled="disabled">
                    <FontAwesomeIcon icon={"circle-notch"} spin /> {q.lenter}
                </button>
            );
        }
        else {
            return (
                <button type="submit" className="btn btn-primary" >
                    <FontAwesomeIcon icon={"sign-in-alt"}/> {q.lenter}
                </button>
            );
        }

    };

    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците
        const ac = AppData.config;

        let changeLogHref;
        switch (ac.defaultLang) {
            case "bg":
                changeLogHref = "changelog_bg.html";
                break;
            case "en":
                changeLogHref = "changelog_en.html";
                break;

            default:
                changeLogHref = "changelog_en.html";
                break;
        }

        const loginForm = (
            <div>

                <header className={"header-login"}>
                    <div className={"container-fluid"}>
                        <div className={"col-xs-2"}><img src={ac.logo1.src} alt={ac.logo1.text} /></div>
                        <div className={"col-xs-2 pull-right tr"}><img src={ac.logoMobileye.src} alt={ac.logoMobileye.text} /></div>
                    </div>
                </header>

                <div id="content" className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                            <div className="well no-padding">
                                <form action="#" id="login-form" className="smart-form client-form" onSubmit={this.handleOnLogin}>

                                    <header>{q.headerTitle}</header>
                                    <fieldset>
                                        {/*
                                        <LabeledInput
                                            type={"text"} name={"accountname"} label={q.laccount} faIcon={"users"} text={q.cai}
                                            handleOnChange={this.callbackLabeledInputOnChange} />
                                        */}
                                        <LabeledInput
                                            type={"text"} name={"username"} label={q.luser} faIcon={"user"} text={q.cui}
                                            handleOnChange={this.callbackLabeledInputOnChange} />
                                        <LabeledInput
                                            type={"password"} name={"password"} label={q.lpassword} faIcon={"lock"} text={q.cpi}
                                            handleOnChange={this.callbackLabeledInputOnChange} />
                                    </fieldset>
                                    <footer>{this.submitButton()}</footer>

                                </form>
                            </div>
                            <div className="alert alert-warning">
                                <strong>{q.lmw}</strong><br />
                                {q.mBeta}<br />
                                ver. {AppData.ver} {q.lsee} <a href={changeLogHref} target={"_blanc"}>Changelog</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
);

        return(
            <div className={"app-login"}>
                {loginForm}
            </div>

        )
    }
}
export default Login;
