import React, {Component, createRef} from 'react';
import { AppData, getById, changeLang, showMsgSmart, postRequest, positiveButtons, logout, fullScreen} from "./framework/bundle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LeftDrawer from "./LeftDrawer";
import DownDrawer from "./DоwnDrawer";
import GpsMap from "./GpsMap";
import ModalReports from "../reports/ModalReport";
import ModalWindow from "./basic/ModalWindow";
import AppMenu from "./basic/AppMenu";
import Loading from "./Loading";

//import BootstrapDropDawnMenu from "./basic/BootstrapDropDawnMenu";
//import Track from "../core/items/Track";
//import TrackHandle from "../core/items_handle/TrackHandle";
//import {calculateSourceResolution} from "ol/reproj";

class WorkSpace extends Component{
    constructor(props){
        super(props);
        //const test = [{value:1, title:"test1"},{value:2, title:"test2", selected:"selected"}]
        this.state = {
            data: [],
            tracks: [],
            total_data: null,
        };

        this.options = {
            track_only: false,
        };
        //this.workSpaceRef = props.ref;
        this.myRefs = {
            //TopLevel Refs
            leftDrawer: createRef(),
            downDrawer: createRef(),
            modal: createRef(),
            map: createRef(),

            //LeftDrawer Refs
            accountData: createRef(),
            devicesList: createRef(),
            groupList: createRef(),
            geozonesList: createRef(),

            //DownDrawer Refs
            trackDataTimeSelectBox: createRef(),
            trackDataDetailsBox: createRef(),
            trackDataDetailsMobileye: createRef(),
            trackDataTrackList: createRef(),

            //tracksSelectBox: createRef(),
            //tracksListBox: createRef(),
            //trackInfoBox: createRef(),
            //trackDetailsTable: createRef(),

            modalReport: createRef(),
            modalWindow: createRef(),
            vehicleLog: createRef,

            appMenuRef: createRef(),
            loadingRef: createRef()
        };

        this.data = []; //TODO да се преместят всички дани във workspace
        this.tracks = [];
    }
    componentDidMount(){
        let objLeftDrawer = this.myRefs.leftDrawer.current;
        let objDownDrawer = this.myRefs.downDrawer.current;

        //FULL SCREEN
        /*
        $.root_.on('click', '[data-action="launchFullscreen"]', function(e) {
            //smartActions.launchFullscreen(document.documentElement);
            AppData.launchFullscreen(document.documentElement);
            e.preventDefault();
        });
        */

        this.myObjects = {
            leftDrawer: objLeftDrawer,
            downDrawer: objDownDrawer,
            modal: this.myRefs.modal.current,
            map: this.myRefs.map.current,

            modalReport: this.myRefs.modalReport.current,
            modalWindow: this.myRefs.modalWindow.current,
            //vehicleLog: this.myRefs.vehicleLog.current,

            accountData: objLeftDrawer.props.myRefs.accountData.current,
            groupList: objLeftDrawer.props.myRefs.groupList.current,
            devicesList: objLeftDrawer.props.myRefs.devicesList.current,
            geozonesList: objLeftDrawer.props.myRefs.geozonesList.current,

            trackDataTimeSelectBox: objDownDrawer.props.myRefs.trackDataTimeSelectBox.current,
            trackDataDetailsBox: objDownDrawer.props.myRefs.trackDataDetailsBox.current,
            trackDataDetailsMobileye: objDownDrawer.props.myRefs.trackDataDetailsMobileye.current,
            trackDataTrackList: objDownDrawer.props.myRefs.trackDataTrackList.current,

            //tracksSelectBox: objDownDrawer.props.myRefs.tracksSelectBox.current,
            //tracksListBox: objDownDrawer.props.myRefs.tracksListBox.current,
            //trackInfoBox: objDownDrawer.props.myRefs.trackInfoBox.current,
            //trackDetailsTable: objDownDrawer.props.myRefs.trackDetailsTable.current,

            appMenu: this.myRefs.appMenuRef.current,
            loading: this.myRefs.loadingRef.current,
        };
        //this.myObjects.leftDrawer.setObjects(this.myObjects);
        //this.myObjects.downDrawer.setObjects(this.myObjects);

        this.myObjects.devicesList.setObjects(this.myObjects);
        //this.myObjects.trackDataTimeSelectBox.setObjects(this.myObjects);
        //this.myObjects.trackDataDetailsBox.setObjects(this.myObjects);
        //this.myObjects.tracksSelectBox.setObjects(this.myObjects);
        //this.myObjects.tracksListBox.setObjects(this.myObjects);
        //this.myObjects.trackInfoBox.setObjects(this.myObjects);
        //this.myObjects.trackDetailsTable.setObjects(this.myObjects);
        this.myObjects.geozonesList.setObjects(this.myObjects);
        //this.myObjects.appMenu.setObjects(this.myObjects);


        this.handleSizing();
        this.load();

    };

    logout=(e)=>{
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let smartMessage = {title:q.mqt, text:q.mqq, buttons:q.smb};
        showMsgSmart(smartMessage, (ButtonClicked) => {
            //Заради езиците когато се сложат езиците ще трябва да се помисли
            //let positiveButtons = ["да", "yes"];
            if (positiveButtons.indexOf(ButtonClicked.toLowerCase()) > -1) {
                this.myObjects.devicesList.stopReloadVehiclesTimer();
                logout();
            }

        },e);
    };
    load=()=>{
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците
        postRequest("load", {}, (rd)=>{
            //console.log(rd);
            if (rd.status === "success") {
                //AccountData
                this.myObjects.accountData.setAccountUser(rd.account, rd.user);
                //GroupList
                //rd.groups.unshift({value:"0", title:q.lAllDevice});
                rd.groups.unshift({value:"0", name:q.lAllDevice});
                //GroupList
                this.myObjects.groupList.setGroups(rd.groups);
                //DeviceList
                rd.devices.forEach((e)=>{
                    e.mapShown = true;
                });
                let i, k = rd.devices.length, devicesShown = [];
                for (i=0;i<k;i++){
                    devicesShown.push(true);
                }
                this.myObjects.devicesList.setDevices(rd.devices);
                this.myObjects.devicesList.setDevicesShown(devicesShown);
                //Опции на устройстава, mobileye, цифрови, аналогови
                this.myObjects.devicesList.setDevicesOptions(rd.devices_options);
                //geozones
                let geozones = this.myObjects.geozonesList;
                geozones.geozonesSet(rd.geozones);
                //geozones

                let map = this.myObjects.map;
                map.mapShowDevices(rd.devices, devicesShown);
                map.mapSetBounds(map.state.devicesLayer);

            }
        });
    };

    handleSizing=()=>{
        //let h = (window.innerHeight - 357)+"px";
        let h = getById("down-drawer");
        let pc = getById("down-panel-content");
        pc.style.height = (h.offsetHeight-80)+"px";
        let height = (h.offsetHeight-80-15);
        let ph = height+"px";
        //let ph2 = (h.offsetHeight-80-15)+"px";
        //getById("panel1").style.height = ph;
        //getById("panel2").style.height = ph;
        //getById("panel3").style.height = ph;
        //getById("panel4").style.height = ph;
        getById("panel5").style.height = ph;
        getById("panelDataTimeSelect").style.height = ph;
        getById("panelTrackDetails").style.height = ph;
        getById("panelTrackDetails").style.height = ph;
        getById("track-list-container").style.height = (height-35)+"px";
        //getById("panelDateDetails").style.height = ph;
    };

    handleFullScreen=(e)=>{
        fullScreen(document.documentElement);
        e.preventDefault();
    };

    //Показва менюто при натескане на бутона
    clickMenuButton=(e)=>{
        //e.preventDefault();
        //let userMenu = this.myRefs.appMenuRef.current;

        //userMenu.openMenu(e);
    };

    //Callback click from right menu and handle item click
    clickMenuItem=(data)=>{

    };

    test=()=>{
        changeLang("en");
    };

    clearData(){

    };
    //Data
    /*
    setData=(data, callback)=>{
        this.setState({data: data}, ()=>{
            if (typeof callback === "function") callback();
        });
    };

    //TotalData

    setTotalData=(total_data, callback)=>{
        this.setState({total_data: total_data}, ()=>{
            if (typeof callback === "function") callback();
        });
    };


    //Tracks
    setTracks(tracks, callback){
        let track_handle = new TrackHandle();
        let track_list = track_handle.tracksArrayToTracksArrayObjects(tracks);
        this.setState({tracks: track_list}, ()=>{
            if (typeof callback === "function") callback();
        })
    };
    clearTracks(callback){
        this.setTracks([], callback)
    }
    //
    */


    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        const Header = (
            <div className={"header-operation "}>

                <div id="user-menu" className="pull-right">
                    <button className="btn btn-default btn-sm header-button"
                            type="button"
                            data-toggle="modal"
                            data-target="#sys-menu-REMOVE"
                            >
                        <FontAwesomeIcon icon="bars" />
                    </button>
                </div>

                <div id="logout" className="pull-right">
                    <button className="btn btn-default btn-sm header-button"
                            onClick={(e) => this.logout(e)}
                            title={q.btnLogout}
                            data-action="userLogout"
                            data-logout-msg="You can improve your security further after logging out by closing this opened browser">
                        <FontAwesomeIcon icon="sign-out-alt" />
                    </button>
                </div>

                {/*
                <div id="settings" className="pull-right">
                    <button className="btn btn-default btn-sm header-button"
                            title={q.btnSettings}>
                        <FontAwesomeIcon icon="cog" />
                    </button>
                </div>
                */
                }

                <div id="fullscreen" className="pull-right">
                    <button className="btn btn-default btn-sm header-button "
                            onClick={(e)=>this.handleFullScreen(e)}
                            data-action="launchFullscreen"
                            title={q.btnFullScreen}>
                        <FontAwesomeIcon icon="expand-arrows-alt" />
                    </button>
                </div>

                <div className={"ver clear"}>
                    <span><b>v. {AppData.ver}</b></span>
                </div>

            </div>
        );

        return(
            <div className={"work-space"}>
                {Header}
                <LeftDrawer
                    ref={this.myRefs.leftDrawer}
                    workSpace={this}
                    lang={l}
                    myRefs={this.myRefs}
                />

                <GpsMap
                    ref={this.myRefs.map}
                    workSpace={this}
                    lang={l}
                    //myRefs={this.myRefs}
                />

                <DownDrawer
                    ref={this.myRefs.downDrawer}
                    workSpace={this}
                    lang={l}
                    myRefs={this.myRefs}

                    tracks={this.state.tracks}
                    data={this.state.data}
                    total_data={this.state.total_data}
                />

                <ModalReports
                    ref={this.myRefs.modalReport}
                    lang={l}
                    myRefs={this.myRefs}

                    modalSizeClass={"modal-xl"}
                />
                <ModalWindow
                    ref={this.myRefs.modalWindow}
                    //id={"modal-window"}
                    lang={l}
                    //myRefs={this.myRefs}

                    modalSizeClass={""}
                />
                <Loading
                    ref={this.myRefs.loadingRef}
                    lang={l}
                />
                <div id={"element"} style={{fontSize:"50px"}}>Element</div>
                <AppMenu
                    ref={this.myRefs.appMenuRef}
                    workSpace={this}
                    lang={l}
                />
            </div>
        );
    }
}
export default WorkSpace;
