export const showMsg = (message, addText="") => {
    window.toastr.options = {
        "closeButton":true,
        "debug":false,
        "progressBar":true,
        "preventDuplicates":false,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "newestOnTop": true,
    };

    if (addText !== '') addText = '<br>'+addText;

    switch (message.type){
        case 'success':
            window.toastr.success(message.text+addText, message.title);
            break;
        case 'error':
            window.toastr.error(message.text+addText, message.title);
            break;
        case 'warning':
            window.toastr.warning(message.text+addText, message.title);
            break;
        case 'info':
            window.toastr.info(message.text+addText, message.title);
            break;
        default:
            window.toastr.success(message.text+addText, message.title);
            break;
    }
};

export const showMsgSmart = (smartMessage, callback, e=null) => {
    //window.$("#smart-mod-eg1").click(function(e) {
        window.$.SmartMessageBox({
            title : smartMessage.title,
            content : smartMessage.text,
            buttons : smartMessage.buttons,//'[No][Yes]'
        }, function(ButtonPressed) {
            if (typeof callback === "function"){
                callback(ButtonPressed);
            }
        });
        if (e !== null) e.preventDefault();
    //})
};

export const postRequest = (op, data, callbackData, callbackError) => {
    let url = AppData.config.server;
    let formData = new FormData();
    formData.append('op',op);
    formData.append('data', JSON.stringify(data));

    fetch(url, {
        method: "POST",
        credentials: "include", // include, same-origin, *omit
        mode: "cors",
        cache: "no-cache",
        body: formData //JSON.stringify(data)
    })
        .then(response => response.json())
        .then(responseData => {
            if ((op !== "login")&&(op !== "logout")){
                if (!responseData.isLogged) logoutEvent();
            }
            if (typeof callbackData === "function"){
                callbackData(responseData);
            }
        })
        .catch((responseData, error) => {
            //let messageText = responseData.error || "Unknown error";
            //let errorMsg = {type:"error", title:"error", text:messageText};
            //showMsg(errorMsg, error);
            if (typeof callbackError === "function"){
                callbackError(error);
                //logout();
            }
        });
};

export const customEvent = (name, details = {}) =>{
    let target = document.getElementById("appID");
    if (target) {
        let event = new CustomEvent(name, {
            detail: details,
            bubbles: true,
            cancelable: true
        });
        target.dispatchEvent(event);
    }
};

export const logoutEvent=()=>{
    customEvent("logoutEvent");
};
export const logout =()=>{
    postRequest("logout", {}, ()=>{
        AppData.user = AppData.account = AppData.groups = {};
        logoutEvent();
    });
};
export const changeLang=(lang)=>{
    //TODO check for allowed languages
    customEvent("changeLang", {lang:lang});
};
export let AppData = {};

export const findParentDataTag=(el, tag)=>{
    let tagFinded = false;
    let tagv = false;
    let iel = el;
    while (!tagFinded){
        if (iel.hasAttribute(tag)) {
            tagFinded = true;
            tagv = iel.getAttribute(tag);
        }
        else {
            iel = iel.parentNode;
        }
        if (iel === document.body) tagFinded = true;
    }
    return tagv;
};

export const colorByCode=(code)=>{
    let c;
    let ac = AppData.config;
    switch (code){
        case "61713":
            c = ac.colors.blue;
            break;
        case "61714":
            c = ac.colors.green;
            break;
        case "61715":
            c = ac.colors.red;
            break;
        case "61716":
            c = ac.colors.purple;
            break;
        case "62529":
            c = ac.colors.yellow;
            break;
        case "62497":
            c = ac.colors.yellow;
            break;

        default:
            c = ac.colors.grey;
            break;
    }
    return c
};

//Get element by id
export const getById =(id)=>{
    return document.getElementById(id);
};
//Заради тъпия smartMessage
export const positiveButtons = ["да", "yes"];

//URI Decode & (+) remove
export const decodeUriString=(st)=>{
    return decodeURIComponent(st).replace(/\+/g, " ");
};

export const uniqueId=(elementId)=>{
    //Ако са зареденив сички елементи иначи няма да е uniqueId
    let uniqueId = 0;
    let isUnique = false;

    while (!isUnique){
        if (document.getElementById(elementId) === null) isUnique = true;
        else {
            elementId = elementId+"-"+uniqueId;
            uniqueId++;
        }
    }

    return elementId;
};

// LAUNCH FULLSCREEN
export const fullScreen=(element)=>{

    let app = window.$("#appID");

    if (!app.hasClass("full-screen")) {
        app.addClass("full-screen");
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    } else {
        app.removeClass("full-screen");
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
};

export const timeDuration=(totalSeconds, separator=":")=>{
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - hours*3600)/60);
    let seconds = Math.floor((totalSeconds-hours*3600-minutes*60));

    //return hours+separator+minutes+separator+seconds;
    //if (hours < 10) hours = "0"+hours.toString();
    if (minutes < 10) minutes = "0"+minutes.toString();
    if (seconds < 10) seconds = "0"+seconds.toString();
    return hours+separator+minutes+separator+seconds;
};

const leadingZero=(number)=>{
    let string;
    if (number < 10 ) string = "0"+number.toString();
    else string = number.toString();
    return string;
};

export const sleep=(millis)=>{
    let date = new Date();
    let curDate = null;
    do { curDate = new Date(); }
    while(curDate-date < millis);
};




//OLD
