import React, {Component} from 'react';
import {AppData, findParentDataTag, customEvent, colorByCode, postRequest} from "../framework/bundle";
import Reports from "../../reports/Reports";
import BootstrapDropDawnMenu from "../basic/BootstrapDropDawnMenu";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import VehicleLog from "../../reports/VehicleLog";
//import MobileyeReport from "../../reports/MobileyeReport";
//import GeozonesReport_OLD from "../../reports/GeozonesReport_OLD";


/* Трети бокс - Списък с устройствата */
class DevicesList extends Component{

    constructor(props){
        super(props);
        this.state = {
            lang: this.props.lang,
            selectedDevice: -1,
            selectedDeviceInfo: {},
            selectedDeviceLastAddress: {},
            //devices: this.props.devices,
            devicesReloading : false,
        };
        this.myRefs = {
            deviceMenuRef: React.createRef(),
        };
        this.reloadVehiclesTimer = null;
        //this.devicesOptions = null;
        this.workSpace = props.workSpace;
    }
    componentDidMount=()=>{
        this.startReloadVehiclesTimer();
    };

    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
    };
    setDevices=(devices)=>{
        let i, le = devices.length;
        for (i=0; i<le; i++) {
            /* Цветове и граници на координатите */
            devices[i]["color"] = colorByCode(devices[i].statusCode);
        }
        this.setState({devices: devices});
    };
    setDevicesShown=(devicesShown)=> {
        this.devicesShown = devicesShown;
    };
    setDevicesOptions=(devicesOptions)=> {
        this.devicesOptions = devicesOptions;
    };

    /* Single Click върху устройство или избор на устройство*/
    deviceSingleClick=(e)=>{
        const el = e.target;
        //if (el.hasAttribute("data-itemtype")) {//device-checkbox-label
        if ((el.getAttribute("data-itemtype") !== 'device-checkbox') && (el.getAttribute("data-itemtype") !== 'device-checkbox-label')) {
            this.deviceSelect(el);
            //this.workSpace.myObjects.trackDataTimeSelectBox.setDeviceOptions
        }
        //}

    };
    /* Dbl Click на устройство */
    deviceDoubleClick=(e)=>{
        const el = e.target;
        if ((el.getAttribute("data-itemtype") !== 'device-checkbox') && (el.getAttribute("data-itemtype") !== 'device-checkbox-label'))  this.deviceSelect(el, 17);
    };
    /* Кликване с десен бутон върху устройствата */
    /* deviceMenu */
    deviceRightClick=(e)=>{
        e.preventDefault();
        const el = e.target;
        if (el.type !== 'checkbox') {
            this.deviceSelect(el);
            let menu = this.myRefs.deviceMenuRef.current;
            menu.openMenu(e);
        }
    };
    deviceMenuClick=(data)=>{
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците
        let reportTitle, additionalData = {};
        switch (data.id){
            case "report_road_list":
                //this.reportRoadList();//Пътен лист
                reportTitle = q.reportTitleRoadList+" - ("+this.state.selectedDeviceInfo.device_name+")";
                break;
            case "report_tracks": //Трак лист
                //this.vehicleLogReport();
                reportTitle = q.reportTitleTrackList+" - ("+this.state.selectedDeviceInfo.device_name+")";
                break;

            //case "mobileye_details_report":
            case "report_mobileye_details":
                //this.mobileyeReport(1); //by device
                reportTitle = q.reportTitleMobileyeDetails+" - ("+this.state.selectedDeviceInfo.device_name+")";
                additionalData = {"reportType":1};
                break;

            //case "mobileye_details_by_driver_report":
            case "report_mobileye_details_by_driver":
                //this.mobileyeReport(2); //by driver
                reportTitle = q.reportTitleMobileyeDetailsByDriver+" - ("+this.state.selectedDeviceInfo.device_name+")";
                additionalData = {"reportType":2};
                break;

            case "report_geozones":
                //this.geozonesReport();
                reportTitle = q.reportTitleGeozones+" - ("+this.state.selectedDeviceInfo.device_name+")";
                break;

            default:
                //this.vehicleLogReport();
                break;
        }
        let report = {reportName:data.id, reportTitle: reportTitle, additionalData: additionalData};
        this.report(report);
    };
    deviceMenuBeforeOpen=()=>{
        //TODO ако девайсите не са с мобилай, да се изключват справките от менюто
    };

    /* Избор на устройство */
    deviceSelect=(el, zoom)=>{
        const d = this.state.devices;
        let map = this.myObjects.map;

        //Изчиства заредените тракове
            //customEvent("tracksListClear");
        customEvent("mapClearData");
        //this.workSpace.clearTracks();
        this.workSpace.setState({tracks:[], total_data: null});
        this.workSpace.myObjects.trackDataDetailsBox.trackDataDetailsClear();
        this.workSpace.myObjects.downDrawer.refTrackDataCounterInfo.current.setCounter(0);

        if (this.refs["device-"+this.state.selectedDevice] !== undefined) {
            this.refs["device-"+this.state.selectedDevice].classList.remove("device-active");
        }

        //
        let id = findParentDataTag(el, "data-id");
        this.setState({selectedDevice:id},()=>{
            this.refs["device-"+this.state.selectedDevice].classList.add("device-active");
        });

        //Добавя се инфото на избрания девайс
        let pos = d.map((e)=>{return e.deviceID}).indexOf(id);

        map.mapShowSelectedDevice(this.state.devices[pos]);
        this.setState({selectedDeviceInfo:d[pos]});
        //}

        let lat = parseFloat(d[pos].latitude);
        let lng = parseFloat(d[pos].longitude);

        if ( (!isNaN(lng)) && (!isNaN(lat)) ) {
            customEvent("mapSetCenterEvent", {coordArray:[lng, lat], duration:200});
            if (zoom !== undefined) customEvent("mapSetCenterEvent", {coordArray:[lng, lat], duration:0, zoom:zoom});
            //customEvent("mapSetZoom", {zoom: 12});
        }
        this.showAddressDeviceInfo(pos);

    };
    /* Селектбокс на устройство */
    deviceCheckBox=(e)=> {
        const el = e.target;

        if (el.hasAttribute("data-id")) {
            let id = parseInt(el.getAttribute("data-id"));
            this.devicesShown[id] = el.checked;

            let map = this.myObjects.map;
            //TODO да не премахва траковете при включване изключване на девайс
            map.mapShowDevices(this.state.devices, this.devicesShown);
        }
            //this.deviceSelect(el, 17);
        //}

    };
    //Таймер за лайв
    startReloadVehiclesTimer=()=>{
        this.reloadVehiclesTimer = setInterval(()=>{this.reloadVehicles()}, AppData.config.refreshTime);
    };
    stopReloadVehiclesTimer=()=>{
        clearInterval(this.reloadVehiclesTimer);
    };
    reloadVehicles=()=>{
        //Показване на анимацията за loading
        //TODO grupa reloading
        let data = {lang: this.props.lang};
        postRequest("reload-vehicles", data, (rd)=>{
            this.setState({devicesReloading:true});
            this.setDevices(rd.devices);

            //Обновява статуса на селектнатото устройство3
            let address;
            if (this.state.selectedDevice !== -1 ){
                let pos = this.state.devices.map((e)=>{return e.deviceID}).indexOf(this.state.selectedDevice);
                if (this.state.devices[pos] !== undefined) {
                    this.setState({selectedDeviceInfo:this.state.devices[pos]}, ()=>{
                        //След като е селектнато устройството
                        this.showAddressDeviceInfo(pos);
                    });
                    //address = this.getAddressByCoordinates();
                    //this.setAddress()
                }
                //postRequest("")
            }

            let map = this.myObjects.map;
            map.mapShowDevices(rd.devices, this.devicesShown, ()=>{
                //callback to hide loading animation
                setTimeout(()=>{this.setState({devicesReloading:false})}, 1000);
            }, false);
        });
    };

    showAddressDeviceInfo=(deviceID)=>{
        if (
            (this.state.devices[deviceID].latitude !== undefined) &&
            (this.state.devices[deviceID].longitude !== undefined)
        )
            this.getAddressByCoordinates(this.state.devices[deviceID].latitude, this.state.devices[deviceID].longitude, (address)=>{
            if (typeof address.error === "undefined") {
                if (address.address !== null) this.setState({selectedDeviceLastAddress: address.address});
                //resize
                this.workSpace.myObjects.leftDrawer.handleSizing();
            }
        });
        //console.log(address);
    };
    getAddressByCoordinates=(lat, lng, callback)=>{
        //const __this = this;
        let data = {lang: this.props.lang, lat: lat, lng: lng};
        postRequest("get-address-by-coordinates", data, (rd)=>{
            if (typeof callback === "function") callback(rd);
        });
    };
    setAddress=(selectedDeviceLastAddress)=>{
        this.setState({selectedDeviceLastAddress: selectedDeviceLastAddress})
    };

    //Report
    report=(report)=>{
        let modalReport = {
            lang: this.props.lang,
            modal: this.myObjects.modalReport,
            modalSizeClass: "modal-xl",
            selectedDevice: this.state.selectedDevice,
            reportName: report.reportName,
            reportTitle: report.reportTitle,
            additionalData: report.additionalData || {}
            //selectedDeviceInfo: this.state.selectedDeviceInfo,
        };
        let my_report = new Reports(modalReport);
        my_report.prepareReport();
    };
    /*
    vehicleLogReport=()=>{
        // трябва да има компонент ModalReport за
        //let report = (<VehicleLog />);
        //this.props.modalRef.current.test(report);
        let pr = {
            lang: this.props.lang,
            modal: this.myObjects.modalReport,
            modalSizeClass: "modal-xl",
            selectedDevice: this.state.selectedDevice,
            selectedDeviceInfo: this.state.selectedDeviceInfo,
            new_deviceID: this.state.selectedDeviceInfo.new_deviceID
        };
        let vl = new VehicleLog(pr);
        vl.prepareReport();

        //let modal = this.props.modalRef.current;
        //modal.show();
    };
    //Mobileye само общи данни по период/ може да е и по скорост
    //reportType [1|2] 1: по-устройство 2: по шофьор
    mobileyeReport=(reportType)=>{
        // трябва да има компонент ModalReport за
        //let report = (<VehicleLog />);
        //this.props.modalRef.current.test(report);
        let pr = {
            lang: this.props.lang,
            modal: this.myObjects.modalReport,
            modalSizeClass: "modal-xl",
            selectedDevice: this.state.selectedDevice,
            selectedDeviceInfo: this.state.selectedDeviceInfo,
            reportType: reportType,
        };
        let mr = new MobileyeReport(pr);
        mr.prepareReport();


        //let modal = this.props.modalRef.current;
        //modal.show();
    };
    geozonesReport=()=>{
        let gr = {
            lang: this.props.lang,
            modal: this.myObjects.modalReport,
            modalSizeClass: "modal-xl",
            selectedDevice: this.state.selectedDevice,
            selectedDeviceInfo: this.state.selectedDeviceInfo,
            new_deviceID: this.state.selectedDeviceInfo.new_deviceID
        };
        let mr = new GeozonesReport(gr);
        mr.prepareReport();
    };
    */

    render(){
        const p = this.props;
        const l = p.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let devices;
        if (this.state.devices !== undefined){
            devices = this.state.devices.map((device,i) => {
                return (
                    <div className={"device-item mt2"}
                         ref={`device-${device.deviceID}`}
                         key={device.deviceID}
                         data-id={device.deviceID}
                         onClick={this.deviceSingleClick}
                         onDoubleClick={this.deviceDoubleClick}
                         onContextMenu={this.deviceRightClick}
                    >
                        <div className={"device-icon"} style={{backgroundColor:device.color}}><FontAwesomeIcon icon={"car"} /></div>
                        <div className={"device-name noselect"}>{device.device_name}</div>
                        <div className={"device-select"}>
                            {/*<input name={""} type="checkbox" data-id={device.deviceID} onInput={this.deviceCheckBox} defaultChecked />*/}
                            <div className="form-check abc-checkbox abc-checkbox-primary" style={{paddingTop:"4px"}}>
                                <input className="form-check-input" id={"checkbox"+i} type="checkbox"
                                       data-id={i}
                                       data-deviceid={device.deviceID}
                                       data-itemtype="device-checkbox"
                                       onInput={this.deviceCheckBox} defaultChecked />
                                <label className="form-check-label" htmlFor={"checkbox"+i} type={"label"} data-itemtype="device-checkbox-label" />
                            </div>
                        </div>
                        <div className={"clear"} />
                    </div>
                )
            });
        }

        const deviceInfo = (
            <div className={"device-info mt5"} id={"device-info"}>
                <div className={"title"} />

                <div className={"mt5"}>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.tDevice}: </strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.device_name}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.ldriver}: </strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.driverName}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-3"}><strong>{q.lLastAddress}: </strong></div>
                        <div className={"col-xs-9 pull-right text-right text-danger fs10"}>{this.state.selectedDeviceLastAddress.address}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.lLastEvent}: </strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.lastEventConv}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.lLastConnection}: </strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.ltcTimeConv}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.lOdometer}: </strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.odometerKM}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.lMotoHours}: </strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.engineHours}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-xs-6"}><strong>{q.lImei} :</strong></div>
                        <div className={"col-xs-6 pull-right text-right text-primary"}>{this.state.selectedDeviceInfo.imei}</div>
                    </div>
                </div>

            </div>
        );
        let deviceMenuItems = [
            {name:q.deviceMenuRoadList, id:"report_road_list"},
            {name:q.deviceMenuTrackList, id:"report_tracks"},
            {name:null},
            {name:q.deviceMenu2, id:"report_mobileye_details", disabled:true, image:"img/icon32/mobileye.png"},
            /* {name:q.deviceMenu21, id:"report_mobileye_details_by_driver", disabled:false, image:"img/icon32/mobileye.png"}, */
            {name:null},
            {name:q.deviceMenu3, id:"report_geozones"},
            //{name:"Mobileye Details", disabled:true, image:"img/icon32/mobileye.png"},
        ];
        const deviceDropDawnMenu = (
            <BootstrapDropDawnMenu
                id={"device-menu"}
                ref={this.myRefs.deviceMenuRef}
                //menuNames={q.deviceMenuList}
                menuObjects={deviceMenuItems}
                onMenuClick={(data)=>{this.deviceMenuClick(data)}}
                onBeforeOpen={this.deviceMenuBeforeOpen}
            />
        );

        //Показване на лоадинг докато се презареждат устройствата
        let deviceReloadingLoading;
        if (this.state.devicesReloading){
            deviceReloadingLoading = (
                <div className={"pull-right"} style={{fontSize:"16px"}}>
                    <FontAwesomeIcon icon="sync" spin color="#9cb4c5" />
                </div>
            );
        }
        else {
            deviceReloadingLoading = "";
        }
        return(
            <div>
                <div className={"title"}>
                    <h2 className={"pull-left"}>{q.titleDevices}</h2>
                    {deviceReloadingLoading}
                    <div className={"clear"} />
                </div>

                <div className={"device-list mt5"} id={"device-list"}>
                    {devices}
                </div>

                {deviceInfo}
                {deviceDropDawnMenu}
            </div>
        );
    };

} export default DevicesList;

/*
class DeviceAddress {
    constructor(brand) {
        this.address = null;
        this.lat = null;
        this.lng = null;
    }
    setAddress=(address)=>{
        this.address = address;
    };
    getAddress=(lat, lng)=>{
        return "GetAddressGetter";
    };
};
 */
