const bg = {
    //all
    laccount: "Акаунт",
    luser: "Потребител", //userLabel
    lpassword: "Парола",
    lenter: "Вход", //loginFormButton
    tDevice: "Устройство",
    lDevices: "Устройства",
    lGeozones: "Геозони",
    lauto: 'Автомобил', //auto //LeftDrawer - deviceInfo
    ldriver: 'Водач', //driver //LeftDrawer - deviceInfo
    lsee: 'виж',
    lSave: 'Запис',
    lCancel: 'Отказ',
    lClose: 'Затвори',
    lReport: 'Справка',
    lLoad: "Зареди",
    lPeriod: 'Период',
    lChoicePeriod: 'Изберете период',
    lToday: 'Днес',
    lLast24: 'Последните 24 часа',
    lYesterday: "Вчера",
    lThisWeek: "Тази седмица",
    lLastWeek: "Миналата седмица",
    lThisMonth: "Този месец",
    lLastMonth: "Миналият месец",
    lAdditional: "Допълнителни данни",

    lFromTo: 'От-до',
    lTimeFromTo: 'Време от-до',
    lDrivingTime: 'Време в движение',
    lDistanceKm: 'Пробег КМ',
    lAvgSpeed: 'Средна скорост',
    lStopsCount: 'Брой спирки',
    lFuelConsum: 'Среден разход Л.',
    lUrbanConsum: 'Градски Л.',
    lExtraUrbanConsum: 'Извънградски Л.',
    lKm: "км.",
    lL: "л.",
    lKmh: "km/ч",
    lLoading: "Зареждане",
    lAddress: "Адрес",
    lLastAddress: "Последен адрес",

    lInformation: "Информация",

    primaryDriver: 'Основен водач', //LeftDrawer - deviceInfo
    lLastEvent: 'Последно събитие', //LeftDrawer - deviceInfo
    lLastConnection: 'Последна връзка', //LeftDrawer - deviceInfo
    lOdometer: 'Одометър', //LeftDrawer - deviceInfo
    lMotoHours: 'Моточасове', //LeftDrawer - deviceInfo
    lStopedTime: "Престой",
    lImei: "IMEI",

    lmw: 'Внимание!', //labelMessageWarning
    lme: 'Грешка!', //labelMessageError
    lms: 'Съобщение', //labelMessageSuccess
    lmi: 'Информация', //labelMessageInfo

    //Login
    headerTitle: "Вход в системата",
    cai: "Въведете вашият акаунт", //accountInputCaption
    cui: "Въведете вашето потребителско име", //userInputCaption
    cpi: "Въведете вашата парола", //passwordInputCaption
    cfp: "Забравена парола", //loginForgotenPassword

    //WorkSpace
    btnLogout: "Изход", //buttonLogout
    btnSettings:  "Настройки",
    btnFullScreen:  "Цял екран",

    //LeftDrawer
    titleAcount: "Акаунт",
    titleFilter: "Филтър",
    titleDevices: "Устройства", //deviceLabel
    lAllDevice: "Всички устройства", //allDeviceLabel

    //deviceMenuList: ["Пътен лист", null, "Второ меню"],
    deviceMenuRoadList: "Пътен лист",
    deviceMenuTrackList: "Тракове",
    deviceMenu1: "Тракове",
    deviceMenu2: "Mobileye детайли",
    deviceMenu21: "Mobileye детайли за шофьор",
    deviceMenu3: "Геозони",

    //Dawn Drawer
    tDawnDrawer: "Детайли за устройство",//dawnDrawerTitle
    lStartDate: "Начална дата",
    lEndDate: "Крайна дата",
    lCourses: "Курсове",
    lDayCourses: "Обединени курсове",
    lMovements: "Движение",
    lTrackDetails: "Детайли за трак",
    lTrackDataDetails: "Детайли за движение",
    lMobileyeTrackDetails: "Mobileye детайли",
    lTracks: "Тракове",
    lMovementDistance: "Изминато разтояние:",
    lMovementTime: "Време (движение)",
    lStopTime: "Време (стоп)",
    lParkTime: "Време (паркиран)",

    //Smart messages buttons
    smb:"[Не][Да]",
    //messages
    mlf: "Попълнете всички полета!", //messageLoginFields
    mqt: "Изход от системата",
    mqq: "Наистина ли искате да излезете от системата",

    mlw  : "Грешен акаунт, потребителско име или парола!", //messageLoginWrong
    mlud : "Този потребител е изключен!", //messageLoginUserDisabled
    mlad : "Този акаунт е изключен!", //messageLoginAccountDisabled

    mcd : "Изберете устройство",
    mnt: "Няма трак по избраните критерии или връзка със сървара!",
    mntr: "Няма налични данни, по избраните критерии!",
    mOneMonthLimit: "Избраният период не може да бъде по-голям от 31 дена!",
    mMobileyeReportSelectDriver: "Моля изберете шофьор!",

    mBeta: "Това е бета версия! Някои от възможностите могат да бъдат изключвани, променяни или може да не работят по време на бета теста!",

    tsdates: "Изберете устройство, начална и крайна дата!",
    tscourse: "Задай дата и зареди движения!",
    tsChosePeriod: "Изберете период от време",

    //Geozones
    lGOperations:"Операции с геозони",
    lGList:"Списък геозони",
    mGeozoneNoEvents: "Няма събития в този период",
    //Geozones end

    //Sysmenu
    sysMenuTextAccounts:"Акаунт",
    sysMenuTextUsers:"Потребители",
    //Sysmenu end

    //Reports
    reportTitleRoadList: "Пътен лист",
    reportTitleTrackList: "Тракове",
    reportTitleMobileyeDetails: "Mobileye детайли",
    reportTitleMobileyeDetailsByDriver: "Mobileye детайли за шофьор",
    reportTitleGeozones: "Геозони",

    msgReportNoEvents: "Няма събития за зададените дати",
};

export default {bg}
