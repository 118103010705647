import React, {Component} from "react";
//import {uniqueId} from "../framework/bundle";

class BootstrapDropDawnMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            openClass: props.openClass,//open
            menuObjects: props.menuObjects,
            x:0,
            y:0,
            id: props.id,
            //id: uniqueId("right-menu"),
            //Може да се направи уникално но само когато вече съществуват всички
        };

        this.timer = null;
    }

    componentDidMount(){

        document.onclick = this.inputChange;
        //this.setState({id:uniqueId("right-menu")})
    };

    inputChange=(e)=> {
        try {
            //if (e.target.getAttribute("data-dropdownmenu") !== "dropdown_menu_"+this.state.id) this.closeMenu();
            //else this.closeMenu();
            this.closeMenu();
        }
        catch(e){
            this.closeMenu();
        }

    };

    handleClick=(e)=>{
        let id = e.target.getAttribute("data-id");
        this.closeMenu();
        let data = {
            id: id,
            //menuName: this.props.menuNames[id]
            //menuName: this.props.menuObjects.menuName[id]
            //menuName: this.props.menuObjects.menuName[id]
        };

        this.props.onMenuClick(data);
    };

    beforeOpen=(beforeOpenCallback)=>{
        if (typeof this.props.onBeforeOpen === "function") this.props.onBeforeOpen();
        /* Open Menu after execute before open method */
        beforeOpenCallback();
    };

    //openMenu=(x,y)=>{
    openMenu=(e)=>{
        //Изпълнява се първо beforeOpen и като преключи тогава опен;
        e.preventDefault();
        let yScroll = window.document.documentElement.scrollTop;
        let xScroll = window.document.documentElement.scrollLeft;

        this.beforeOpen(()=>{
            //e.clientX+xScroll, e.clientY+yScroll
            //this.setState({x:x, y:y, openClass: "open"},()=>{
                //document.getElementById(this.state.id).focus();
            //});
            this.setState({x:e.clientX+xScroll, y:e.clientY+yScroll, openClass: "open"},()=>{
                document.getElementById(this.state.id).focus();
            });
        });
    };
    closeMenu=(callback)=>{
        this.setState({openClass: ""},()=>{
            if (typeof callback === "function") callback();
        });
    };

    render(){
        //const p = this.props;
        //let li = p.menuNames.map((m,i)=>{
        let li = this.state.menuObjects.map((m,i)=>{
            let k = this.state.id+`-item-`+i;
            //let classDisabled = "";
            //if (m.disabled) classDisabled = 'disabled';

            if (m.name !== null) return (
                <li key={k} data-focusgroup={this.state.id} className={m.disabled ? 'disabled' : null}>
                    <button onClick={this.handleClick} data-id={m.id} data-dropdownmenu={"dropdown_menu_"+this.state.id} >{m.name}</button>
                </li>
            );
            else return (<li key={k} className={"divider"} tabIndex={"-1"} />);
        });
        return (
            <div
                className={this.state.openClass}
                id={this.state.id}
                tabIndex="-1"
                onBlur={this.handleBlur}
            >
                <ul
                    style={{top: this.state.y+"px", left: this.state.x+"px"}}
                    className="dropdown-menu"
                    role="menu"
                    data-focusgroup={this.state.id}
                >
                    {li}
                </ul>
            </div>
        );
    }
}
export default BootstrapDropDawnMenu;