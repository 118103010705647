import React, {Component} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { AppData, customEvent, findParentDataTag, positiveButtons, postRequest, showMsg, showMsgSmart } from "../framework/bundle";
import { toLonLat, fromLonLat} from 'ol/proj.js';

class GeozonesList extends Component{

    constructor(props){
        super(props);
        this.state = {
            lang: this.props.lang,
            draw: null,
        };
    }

    setObjects=(myObjects)=>{
        this.myObjects = myObjects;
    };

    geozoneModalShow=()=>{
        let modal = this.myObjects.modalWindow;

        let modalBody = (
            <div>
                <div className={"col-xs-12"}>
                    <div className="form-group">
                        <label htmlFor="geozone-name">Geozone name</label>
                        <input type="name" className="form-control" id="geozone-name" placeholder="Geozone name" onInput={(e)=>{this.geozoneNameInput(e.target.value);}} />
                    </div>
                </div>
            </div>
        );

        let modalButtons = (
            <div>
                <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.geozoneDrawModeStop}>Close</button>
                <button type="button" className="btn btn-success" onClick={this.geozoneSave} >Save</button>
            </div>
        );

        modal.setTitle("Save Geozone");
        modal.setBody(modalBody);
        modal.setButtons(modalButtons);
        modal.show();
        //this.myObjects.modalWindow.show();
        //let modal = this.myObjects
    };
    geozoneNameInput=(name)=>{
        this.geozoneName = name;
    };
    geozoneDrawModeStart=()=>{
        this.geometryArray = null;

        this.myObjects.map.geozoneRemoveLayer();
        this.geozoneDeselect();

        this.myObjects.map.drawStart((geometryArray)=>{this.geozoneDrawComplete(geometryArray)});
    };
    geozoneDrawModeStop=()=>{
        let map = this.myObjects.map;

        this.geometryArray = null;
        map.drawRemoveLayer();
        map.drawStop();
    };
    geozoneDrawComplete=(geometryArray)=>{
        this.geometryArray = geometryArray;
        this.geozoneModalShow();
    };
    geozoneSave=()=>{
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците
        let minLng=null, maxLng=null, minLat=null, maxLat=null, first=true, newLonLng, geometryArrayLonLng = [];

        if ((this.geozoneName !== "") && (this.geozoneName !== undefined)){
            this.geometryArray.forEach((ga)=>{
                newLonLng = toLonLat(ga);
                geometryArrayLonLng.push(newLonLng);
                if (first){
                    minLat = parseFloat(newLonLng[1]);
                    maxLat = parseFloat(newLonLng[1]);
                    minLng = parseFloat(newLonLng[0]);
                    maxLng = parseFloat(newLonLng[0]);
                    first=false;
                }
                else {
                    minLat = Math.min(minLat,parseFloat(newLonLng[1]));
                    maxLat = Math.max(maxLat,parseFloat(newLonLng[1]));
                    minLng = Math.min(minLng,parseFloat(newLonLng[0]));
                    maxLng = Math.max(maxLng,parseFloat(newLonLng[0]));
                }
            });

            let data = {
                lang: l,
                name: this.geozoneName,
                minLat: minLat,
                maxLat: maxLat,
                minLng: minLng,
                maxLng: maxLng,
                geometryType: 1,//TODO когато се добави и кръгче
                //geometryArray: this.geometryArray,
                geometryArray: geometryArrayLonLng,
            };
            postRequest("geozone-add", data, (rd)=>{
                if (rd.status === "success"){
                    let msg = {type:"success", title:q.lms, text:"Geozone saved"};
                    showMsg(msg);
                }
                else {
                    let msg = {type:"error", title:q.lms, text:"Server error:<br>"+rd.error};
                    showMsg(msg);
                }
            });
            this.geozonesReload();
            this.geozoneDrawModeStop();
            this.myObjects.modalWindow.hide();
        }
        else {
            //let modal = this.myObjects.modalWindow;
            let msg = {type:"warning", title:q.lmw, text:"Please enter name!"};
            showMsg(msg);
        }

    };
    geozoneDelete=()=>{
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        if (this.state.selectedGeozone !== undefined){

            let smartMessage = {title:"Confirm Geozone delete!", text:"Delete selected geozone?", buttons:q.smb};
            showMsgSmart(smartMessage, (ButtonClicked) => {
                if (positiveButtons.indexOf(ButtonClicked.toLowerCase()) > -1) {
                    let data = {geozoneID: this.state.selectedGeozone};
                    postRequest("geozone-delete", data, (rd)=>{
                        this.geozonesReload();
                        this.myObjects.map.geozoneRemoveLayer();

                        let msg = {type:"success", title: q.lms, text: "Geozone deleted"};
                        showMsg(msg);
                    });
                }
            });

        }
        else{
            let msg = {type:"warning", title:q.lmw, text:"Select geozone to delete it!"};
            showMsg(msg);
        }

    };
    geozonesSet=(geozones)=>{
        if (geozones !== null){
            geozones.forEach((g)=>{
                if (g.geometryLonLng !== null){
                    g.geometry = [];
                    g.geometryLonLng.forEach((gg)=>{
                        g.geometry.push(fromLonLat(gg));
                    });
                }
            });
        }
        else geozones = [];

        this.setState({geozones: geozones});
    };

    geozoneSingleClick=(e)=>{
        const el = e.target;
        this.geozoneSelect(el);
        //if ((el.getAttribute("data-itemtype") !== 'device-checkbox') && (el.getAttribute("data-itemtype") !== 'device-checkbox-label')) this.deviceSelect(el);
        //}
    };
    geozoneSelect=(el)=>{
        const g = this.state.geozones;
        let map = this.myObjects.map;

        //Изчиства заредените тракове
        customEvent("tracksListClear");
        customEvent("mapClearData");

        if (this.refs["geozone-"+this.state.selectedGeozone] !== undefined) {
            this.refs["geozone-"+this.state.selectedGeozone].classList.remove("geozone-active");
        }

        //
        let id = findParentDataTag(el, "data-geoid");
        this.setState({selectedGeozone:id},()=>{
            this.refs["geozone-"+this.state.selectedGeozone].classList.add("geozone-active");
        });


        //Добавя се инфото на избрания девайс
        let pos = g.map((e)=>{return e.id}).indexOf(id);
        map.geozoneShowSelected(this.state.geozones[pos]);

        //map.mapShowSelectedDevice(this.state.devices[pos]);
        //this.setState({selectedDeviceInfo:d[pos]});
        //}

        //let lng = parseFloat(d[pos].longitude);
        //let lat = parseFloat(d[pos].latitude);

        /*
        if ( (!isNaN(lng)) && (!isNaN(lat)) ) {
            customEvent("mapSetCenterEvent", {coordArray:[lng, lat], duration:200});
            if (zoom !== undefined) customEvent("mapSetCenterEvent", {coordArray:[lng, lat], duration:0, zoom:zoom});
            //customEvent("mapSetZoom", {zoom: 12});
        }
        */
    };
    geozoneDeselect=()=>{
        this.setState({selectedGeozone:0});
    };
    geozonesReload=()=>{
        let data = {};
        postRequest("geozones-list", data, (rd)=>{
            if (rd.status === "success"){
                this.geozonesSet(rd.geozones);
            }
        });
    };

    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        let geozones;
        if (this.state.geozones !== undefined){
            geozones = this.state.geozones.map((geozone) => {
                return (
                    <div className={"geozone-item mt2"}
                         ref={`geozone-${geozone.id}`}
                         key={geozone.id}
                         data-geoid={geozone.id}
                         onClick={this.geozoneSingleClick}
                         //onDoubleClick={this.deviceDoubleClick}
                         //onContextMenu={this.deviceRightClick}
                    >
                        <div className={"geozone-icon"} style={{backgroundColor:"#0099ff"}}><FontAwesomeIcon icon={"draw-polygon"} /></div>
                        <div className={"device-name noselect"}>{geozone.name}</div>
                        <div className={"clear"} />
                    </div>
                )
            });
        }

        return(
            <div>
                <div className={"title mt5"}><h2>{q.lGOperations}</h2></div>
                <div className="btn-group">
                    <div className={"mt5"}>
                        <button type="button" className="btn btn-default" onClick={this.geozoneDrawModeStart}><FontAwesomeIcon icon={"plus-circle"} size={"2x"} color={AppData.config.colors.green}/></button>
                        <button type="button" className="btn btn-default" onClick={this.geozoneDelete}><FontAwesomeIcon icon={"times-circle"} size={"2x"} color={AppData.config.colors.red}/></button>
                    </div>
                </div>
                <div className={"geozones"}>
                    <div className={"title"}><h2>{q.lGList}</h2></div>
                    <div className={"geozones-list"} id={"geozones-list"}>
                        {geozones}
                    </div>
                </div>
            </div>
        );
    }

} export default GeozonesList

//TODO editing
//http://dev.openlayers.org/examples/modify-feature.html
//TODO delete При изтриване на зоната да се изтрие и от полето
//TODO delete Като смини таба също да се изтриват
//TODO view Може да има един лейър за вскички зони

