import React, {Component} from "react";

class BootstrapDataTimePicker extends Component {
    componentDidMount(){
        //const p = this.props;

        //window.$(`#${p.id}`).on("dp.change", function (e) {
            //$('#datetimepicker7').data("DateTimePicker").minDate(e.date);
        //});

    };
    componentDidUpdate(){
        /*
        window.$(function () {

        });
        */
        //this.initialize();
    };

    initialize=(d)=>{
        const p = this.props;
        const l = p.lang;

        let dd = d || p.date;
        window.$(`#${p.id}`).datetimepicker({
            locale: l,
            defaultDate: dd //p.date
        });
    };

    setDateTime=(newDate)=>{
        const p = this.props;
        //const l = p.lang;

        window.$(`#${p.id}`).data("DateTimePicker").date(window.moment(newDate));
    };

    render(){
        const p = this.props;
        return (
    <div className="form-group">
        <div className={"input-group date"} id={p.id}>
            <input type='text' className="form-control"/>
            <span className="input-group-addon">
                <span className="glyphicon glyphicon-calendar" />
            </span>
        </div>
    </div>
);
    }
}
export default BootstrapDataTimePicker;