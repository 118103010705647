import React, {Component} from "react";
//import BootstrapModal from "../components/basic/BootstrapModal"
import {AppData, customEvent, uniqueId} from "../components/framework/bundle";
//import BootstrapDataTimePicker from "../basic/BootstrapDataTimePicker";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ModalReports extends Component {

    constructor(props){
        super(props);
        //let modalSizeClass = props.modalClass || ""; //modal-lg, modal-sm

        this.state = {
            id: uniqueId("modal-id"),
            bodyId: uniqueId("modal-body"),
            modalSizeClass: props.modalSizeClass || "", //modal-lg, modal-sm, modal-xl

            modalTitle: props.modalTitle || "",
            modalBody: props.modalBody || "",
            modalBodyStyle: {},
            buttons: [],
        };

        this.timer = null;
    }

    componentDidMount(){
        let e = AppData.obj.app;
        e.addEventListener("app-resize", this.handleSizing);
        //e.addEventListener("", this.handleSizing);
        this.handleSizing();
    }
    componentWillUnmount(){
        let e = AppData.obj.app;
        e.removeEventListener("app-resize", customEvent, false);
    }
    /*
    test=(report)=>{
        this.setState({report: report});
    };
    */


    handleSizing=()=>{
        let e = document.getElementById(this.state.bodyId);
        if (e){
            let h = (window.innerHeight - 260)+"px";
            e.style.height = h;

        }

    }

    show=()=>{
        window.$(`#${this.state.id}`).modal('show');
    };
    hide=()=>{
        window.$(this.state.id).modal('hide');
    };

    onShow=()=>{
        //$('#myModal').on('hidden.bs.modal', function (e) {
            // do something...
        //})
    };
    onHide=()=>{

    };

    //Text
    setTitle=(title)=>{
        this.setState({modalTitle: title});
    };
    //JSX
    setBody=(content, callback)=>{
        this.setState({modalBody: content},()=>{
            if (typeof callback === "function"){
                callback();
            }
        });
    };
    setBodyStyle=(styleObj)=>{
        this.setState({modalBodyStyle:styleObj});
    };
    //JSX
    setToolbar=(content, callback)=>{
        this.setState({modalToolbar: content},()=>{
            if (typeof callback === "function"){
                callback();
            }
        });
    };
    //JSX
    setForm=(content, callback)=>{
        this.setState({modalForm: content},()=>{
            if (typeof callback === "function"){
                callback();
            }
        });
    };

    render(){
        const l = this.props.lang;
        const q = AppData.langs[l]; //Масива с езиците

        return (
            <div className="modal fade" tabIndex="-1" role="dialog" id={this.state.id}>
                <div className={`modal-dialog ${this.state.modalSizeClass}`} role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{this.state.modalTitle}</h4>
                        </div>

                        <div>
                            {this.state.modalToolbar}
                        </div>

                        <div
                            style={this.state.modalBodyStyle}
                            id={this.state.bodyId}
                            className="modal-body">
                            {this.state.modalBody}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">{q.lClose}</button>
                        </div>

                            {this.state.modalForm}

                        {/*this.state.report*/}
                    </div>
                </div>
            </div>
        );
    }
}
export default ModalReports;
